import {
  useAppSelector,
  useAppDispatch,
  disconnectWallet,
  RootState,
  //  useToastDispatch
} from 'ds_share'
// import { useCallback } from 'react'
// import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { useDisconnect } from 'wagmi'

import { paperSDKLogout } from '../lib/paperSDK'

// import { paperSDK } from '../lib/paperSDK'

// const NFT_RETENTION_PATHS = ['/dashboard', '/live', '/settings']

export const useDisConnect = () => {
  const paperAuthState = useAppSelector((state: RootState) => state.auth)
  // const connectedWallet = useAppSelector((state) => state.auth.connectedWallet)
  // const isConnectWallet = useAppSelector((state) => state.auth.isConnectWallet)
  const authDispatch = useAppDispatch()
  // const authDispatch = useAuthDispatch()
  // const toastDispatch = useToastDispatch()

  // const location = useLocation()
  // const navigate = useNavigate()
  // const { storeName } = useParams()

  const { disconnect } = useDisconnect()

  const handleDisConnect = useCallback(() => {
    // if (!paperAuthState.isConnectWallet) return

    try {
      if (paperAuthState.connectedPaperWallet?.authType === 'paper') {
        paperSDKLogout()
        console.log('paperSDKLogout()')
        authDispatch(disconnectWallet())
      } else {
        console.log('disconnect() wagmi')
        disconnect()
      }

      // disconnect()

      // authDispatch?.disConnect(() => {
      //   const isNFTPage = location.pathname.split('/')[2] === 'nft'

      //   if (NFT_RETENTION_PATHS.includes(location.pathname) || (storeName && isNFTPage)) {
      //     navigate('/')
      //   }
      // })

      // toastDispatch?.showToast({ message: 'Success DisConnect', description: '', status: 'SUCCESS' })
    } catch (e) {
      console.error('Failed DisConnect', e)
      // toastDispatch?.showToast({ message: 'Failed DisConnect', description: '', status: 'ERROR' })
    }
  }, [paperAuthState])

  return { handleDisConnect }
}
