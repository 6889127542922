// import { useQuery } from '@apollo/client'
// import { useToastDispatch } from 'ds_share'
import { UserStatus } from '@paperxyz/embedded-wallet-service-sdk'
import {
  connectWallet,
  useAppDispatch,
  type ConnectedPaperWallet,
  paperAuthLoadingStart,
  paperAuthLoadingEnd,
} from 'ds_share'
import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { useAccount } from 'wagmi'

// import { NftItem, NfTsWithMoralisDocument } from '../graphql/types'
// import { KEY_ADDRESS } from '../lib/key'
import { paperSDKGetUser } from '../lib/paperSDK'

export const useAuthCheck = () => {
  // const [paperWalletAddress, setPaperWalletAddress] = useState('')
  // const [, setPaperWalletAddress] = useState('')

  // const authState = useAuthState()
  const authDispatch = useAppDispatch()

  // const walletAddress = useAppSelector((state: RootState) => state.auth.connectedWallet?.walletAddress)
  // const { address: wagmiAddress } = useAccount()

  // const {
  //   loading: moralisLoading,
  //   data: NftWithMoralis,
  //   error: NftWithMoralisError,
  // } = useQuery<{ getNFTsWithMoralisAPI: NftItem[] }>(NfTsWithMoralisDocument, {
  //   variables: {
  //     address: wagmiAddress || paperWalletAddress,
  //     tokenAddresses: [KEY_ADDRESS],
  //   },
  //   skip: isPaperLoading || (!paperWalletAddress && !wagmiAddress),
  // })

  // const authState = useAuthState()
  // const authDispatch = useAuthDispatch()
  // const toastDispatch = useToastDispatch()

  // const navigate = useNavigate()

  useEffect(() => {
    authDispatch(paperAuthLoadingStart())
    const paperData = async () => {
      try {
        const data = await paperSDKGetUser()
        if (data.status === UserStatus.LOGGED_IN_WALLET_INITIALIZED) {
          // setPaperWalletAddress(data.walletAddress)
          const paperConntectedItem: ConnectedPaperWallet = {
            authType: 'paper',
            walletAddress: data.walletAddress,
          }
          authDispatch(connectWallet(paperConntectedItem))
        }
      } catch (e) {
        console.error(e)
      } finally {
        authDispatch(paperAuthLoadingEnd())
      }
    }
    paperData()
  }, [])

  // useEffect(() => {
  //   if (moralisLoading || authState?.isInitialized || isPaperLoading) return

  //   if (NftWithMoralisError) {
  //     authDispatch?.connect({
  //       isInitialized: true,
  //       ownedWallet: {
  //         authType: paperWalletAddress ? 'paper' : 'wagmi',
  //         walletAddress: paperWalletAddress || wagmiAddress,
  //       },
  //       ownedNFT: null,
  //     })

  //     toastDispatch?.showToast({
  //       message: 'Nft acquisition failure',
  //       description: NftWithMoralisError.message,
  //       status: 'ERROR',
  //     })

  //     return
  //   }

  //   if (NftWithMoralis && NftWithMoralis.getNFTsWithMoralisAPI.length) {
  //     authDispatch?.connect(
  //       {
  //         isInitialized: true,
  //         ownedWallet: {
  //           authType: paperWalletAddress ? 'paper' : 'wagmi',
  //           walletAddress: paperWalletAddress || wagmiAddress,
  //         },
  //         ownedNFT: NftWithMoralis.getNFTsWithMoralisAPI,
  //       },
  //       () => {
  //         navigate('/live')
  //       }
  //     )

  //     return
  //   } else {
  //     if (paperWalletAddress) {
  //       authDispatch?.connect({
  //         isInitialized: true,
  //         ownedWallet: { authType: 'paper', walletAddress: paperWalletAddress },
  //         ownedNFT: [],
  //       })

  //       return
  //     }

  //     if (wagmiAddress) {
  //       authDispatch?.connect({
  //         isInitialized: true,
  //         ownedWallet: { authType: 'wagmi', walletAddress: wagmiAddress },
  //         ownedNFT: [],
  //       })

  //       return
  //     }
  //   }

  //   authDispatch?.connect({ isInitialized: true, ownedWallet: null, ownedNFT: null })
  // }, [moralisLoading, NftWithMoralis, isPaperLoading])
}
