import {
  ConnectWalletModal,
  ExploreDeStoreModal,
  HomeCta,
  HomeEnterDeStoreSection,
  HomeExploreDeStoreSection,
  Spacer,
  useTheme,
  WalletConfirmModal,
  useAppSelector,
  type RootState,
} from 'ds_share'
import { useEffect, useMemo, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { ConnectWalletLists } from '../../components/ConnectWalletLists'
import { paperSDKLogin } from '../../lib/paperSDK'

import styles from './Home.module.css'

export const Home = () => {
  const theme = useTheme()
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const { isConnected } = useAccount()

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
  const [isShowConnectModal, setIsShowConnectModal] = useState(false)
  const [isShowExploleModal, setIsShowExploreModal] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (isConnectPaperWallet || isConnected) {
      setIsShowConnectModal(false)
    }
  }, [isConnectPaperWallet, isConnected])

  const handleShowConfirmModal = () => {
    if (isConnectPaperWallet || isConnected) {
      navigate('/stores')
      // if (auth.hasNFT) {
      // navigate('/live')
      // } else {
      //   setIsShowExploreModal(true)
      // }
    } else {
      setIsShowConfirmModal((prev) => !prev)
    }
  }

  const handleShowConnectModal = () => {
    setIsShowConfirmModal(false)
    setIsShowConnectModal((prev) => !prev)
  }

  const handleCloseExploreModal = () => {
    setIsShowExploreModal(false)
  }

  const isShowExploreModal = useMemo(() => {
    if ((isConnectPaperWallet && isShowExploleModal) || (isConnected && isShowExploleModal)) {
      return true
    } else {
      return false
    }
  }, [isConnectPaperWallet, isConnected, isShowExploleModal])

  return (
    <>
      <WalletConfirmModal
        visible={isShowConfirmModal}
        onRequestClose={handleShowConfirmModal}
        handleShowConnectModal={handleShowConnectModal}
        handleCreateWallet={() =>
          paperSDKLogin().then((res) => {
            console.log(res)
            setIsShowConfirmModal(false)
            // setPaperWalletAddress(res.user.walletAddress)
          })
        }
      />
      <ConnectWalletModal
        visible={isShowConnectModal}
        bodyChildren={
          <ConnectWalletLists
            handleNextAction={() => {
              setIsShowExploreModal(true)
            }}
          />
        }
        onRequestClose={handleShowConnectModal}
      />
      <ExploreDeStoreModal visible={isShowExploreModal} onRequestClose={handleCloseExploreModal} />
      <div className={theme?.media.isMobile ? styles.mobileContainer : styles.container}>
        <HomeCta logoImagePath="/logo/logo_icon_white.svg" keyImagePath="/key/key_main_rainbow.svg" />
        <div className={styles.buttonContainer}>
          <HomeEnterDeStoreSection handleOpenClick={handleShowConfirmModal} />
          <Spacer direction="horizontal" space={24} />
          <HomeExploreDeStoreSection />
        </div>
      </div>
    </>
  )
}
