import { useAppSelector, type RootState } from 'ds_share'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'

export const useDisConnectViaExtension = () => {
  const { address, isConnected } = useAccount()

  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.connectedPaperWallet)
  const hasNFT = useAppSelector((state: RootState) => state.auth.ownedDeStoreNFT)

  // const authDispatch = useAuthDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    if (isConnected || address || isConnectPaperWallet) return

    if (hasNFT.length > 0) {
      navigate('/')
    }
  }, [address, isConnected])
}
