// import { useQuery } from '@apollo/client'
import {
  ButtonWithChildren,
  Image,
  Text,
  useTheme,
  connectWallet,
  useAppDispatch,
  useAppSelector,
  closeAllModal,
  type ConnectedPaperWallet,
  type RootState,
} from 'ds_share'
import { useEffect } from 'react'
import { useAccount, useConnect } from 'wagmi'

//import { useNavigate } from 'react-router-dom'

// import {
//   // useAuthDispatch,
//   // useAuthState,
// } from '../contexts/auth'
// import { NfTsWithMoralisDocument } from '../graphql/custom-queries'
// import { NftItem } from '../graphql/types'
// import { KEY_ADDRESS } from '../lib/key'
import { paperSDKLogin } from '../lib/paperSDK'

import styles from './ConnectWalletLists.module.css'

const CONNECTOR_COLORS: { [key in string]: string } = {
  metaMask: '#d7c1b3',
  walletConnect: '#3b99fc',
}

type ConnectWalletListsProps = {
  handleNextAction: () => void
}

export const ConnectWalletLists = ({ handleNextAction }: ConnectWalletListsProps) => {
  const theme = useTheme()
  // const auth = useAuthState()
  const authDispatch = useAppDispatch()
  const { connect, connectors } = useConnect()
  const { address, isConnected } = useAccount()
  const paperWallet = useAppSelector((state: RootState) => state.auth.connectedPaperWallet?.walletAddress)
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  // Todo: remove this to use the global store
  // const [, setPaperWalletAddress] = useState<string | null>(null)

  // const { loading, data } = useQuery<{ getNFTsWithMoralisAPI: NftItem[] }>(NfTsWithMoralisDocument, {
  //   variables: {
  //     address: address || paperWalletAddress,
  //     tokenAddresses: [KEY_ADDRESS],
  //   },
  //   skip: !paperWalletAddress && !address,
  // })

  // const authDispatch = useAuthDispatch()

  // const navigate = useNavigate()

  useEffect(() => {
    // if (loading) return
    if (!address && !paperWallet) return

    // Only if unchecked on first load
    // if (data && data.getNFTsWithMoralisAPI.length) {
    //   authDispatch?.connect(
    //     {
    //       isInitialized: true,
    //       ownedWallet: {
    //         authType: paperWalletAddress ? 'paper' : 'wagmi',
    //         walletAddress: paperWalletAddress || address,
    //       },
    //       ownedNFT: data.getNFTsWithMoralisAPI,
    //     },
    //     () => {
    //       navigate('/live')
    //     }
    //   )

    //   return
    // } else {
    //   authDispatch?.connect({
    //     isInitialized: true,
    //     ownedWallet: { authType: paperWalletAddress ? 'paper' : 'wagmi', walletAddress: paperWalletAddress || address },
    //     ownedNFT: [],
    //   })
    // }

    // if (isConnected && address) {
    //   const wagmiConnectedItem: ConnectedWallet = {
    //     authType: 'wagmi',
    //     walletAddress: address,
    //   }
    //   authDispatch(connectWallet(wagmiConnectedItem))
    //   authDispatch(closeAllModal())
    // }

    if (!isConnectPaperWallet || isConnected) {
      handleNextAction()
    }
  }, [address, isConnected])
  // }, [address, isConnected, loading, data])

  return (
    <>
      <div className={styles.paperContainer}>
        <button
          className="login-with-paper-btn"
          onClick={() =>
            paperSDKLogin().then((res) => {
              // setPaperWalletAddress(res.user.walletAddress)
              const paperConntectedItem: ConnectedPaperWallet = {
                authType: 'paper',
                walletAddress: res.user.walletAddress,
              }
              authDispatch(connectWallet(paperConntectedItem))
              authDispatch(closeAllModal())
            })
          }
        >
          with Email
        </button>
      </div>
      <div className={styles.orText}>Or login with web3 wallet</div>
      {connectors.map((connector) => {
        return (
          <ButtonWithChildren
            key={connector.id}
            customStyle={{
              width: '100%',
              marginTop: 20,
              paddingHorizontal: 20,
              paddingVertical: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: theme?.radius.full,
            }}
            buttonColor={CONNECTOR_COLORS[connector.id]}
            disabled={!connector.ready}
            onPress={() => {
              try {
                if (!isConnected) {
                  connect({ connector })
                  authDispatch(closeAllModal())
                }
              } catch (e) {
                console.log(e)
              } finally {
                authDispatch(closeAllModal())
              }
            }}
          >
            <div className={styles.walletContainer}>
              <Text style={{ color: theme?.color.primaryBlack, fontSize: theme?.fontSize.md, fontWeight: 'bold' }}>
                with
              </Text>
              <Image
                source={{ uri: `../wallet/${connector.id}.png`, width: 28, height: 28 }}
                style={{ marginHorizontal: 12 }}
              />
              <Text style={{ color: theme?.color.primaryBlack, fontSize: theme?.fontSize.md, fontWeight: 'bold' }}>
                {connector.name}
              </Text>
            </div>
          </ButtonWithChildren>
        )
      })}
    </>
  )
}
