import { createBrowserRouter } from 'react-router-dom'

import { useAuthCheck } from './hooks/useAuthCheck'
import { useRouteScrollTop } from './hooks/useRouteScrollTop'
import { webRoutes } from './routes'

import './assets/fonts/ABCWhyteInktrap-Regular.ttf'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

function App() {
  useAuthCheck()
  useRouteScrollTop()
  return null
}

export const router = createBrowserRouter(webRoutes)

export default App
