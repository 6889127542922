import {
  ConnectWalletModal,
  ContainerLayout,
  Header,
  MobileHeader,
  WalletConfirmModal,
  useAppSelector,
  closeAllModal,
  useAppDispatch,
  showConnectModal,
  showConfirmModal,
  type RootState,
  NetworkDifferentModal,
  paperAuthLoadingStart,
  paperAuthLoadingEnd,
} from 'ds_share'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

import { useDisConnect } from '../../hooks/useDisConnect'
import { useDisConnectViaExtension } from '../../hooks/useDisConnectViaExtension'
import { paperSDKLogin } from '../../lib/paperSDK'
import { ConnectWalletLists } from '../ConnectWalletLists'

export const UnauthorizedLayout = () => {
  useDisConnectViaExtension()
  const { handleDisConnect } = useDisConnect()
  const { isConnected, address, isConnecting } = useAccount()
  // const { data: ensName } = useEnsName({ address })
  const { chain: activeChain } = useNetwork()
  const {
    switchNetwork,
    // isLoading
  } = useSwitchNetwork()

  if (process.env.REACT_APP_MAIN_CHAINID === undefined && process.env.REACT_APP_TEST_CHAINID === undefined) {
    throw new Error('ChainId is not defined')
  }
  const correctChainId: string | undefined =
    process.env.REACT_APP_VERCEL_ENV === 'production'
      ? process.env.REACT_APP_MAIN_CHAINID
      : process.env.REACT_APP_TEST_CHAINID

  const [isShowNetworkDiffrentModal, setIsShowNetworkDiffrentModal] = useState(false)
  const isShowConfirmModal = useAppSelector((state: RootState) => state.modalCtrl.isShowConfirmModal)
  const isShowConnectModal = useAppSelector((state: RootState) => state.modalCtrl.isShowConnectModal)
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const isLoadingPaper = useAppSelector((state: RootState) => state.auth.isPaperAuthLoading)
  const paperAddress: string | undefined = useAppSelector(
    (state: RootState) => state.auth.connectedPaperWallet?.walletAddress
  )
  const modalCtrlDispatch = useAppDispatch()
  const getAddressOrEnsName = (): string => {
    return paperAddress ?? (address || '')
  }
  // const connectedWallet = useAppSelector((state: RootState) => state.auth.connectedWallet)

  const navigate = useNavigate()

  useEffect(() => {
    if (activeChain && !(String(activeChain.id) === correctChainId)) {
      setIsShowNetworkDiffrentModal(true)
    } else {
      setIsShowNetworkDiffrentModal(false)
    }
  }, [
    activeChain?.id,
    isShowConfirmModal,
    isConnectPaperWallet,
    isConnected,
    isLoadingPaper,
    isConnecting,
    paperAuthLoadingStart,
    paperAuthLoadingEnd,
  ])
  return (
    <>
      <WalletConfirmModal
        visible={isShowConfirmModal}
        onRequestClose={() => modalCtrlDispatch(closeAllModal())}
        handleShowConnectModal={() => modalCtrlDispatch(showConnectModal())}
        handleCreateWallet={() =>
          paperSDKLogin().then((res) => {
            console.log(res)
            // setPaperWalletAddress(res.user.walletAddress)
          })
        }
      />

      <NetworkDifferentModal
        visible={isShowNetworkDiffrentModal}
        onRequestClose={() => setIsShowNetworkDiffrentModal(false)}
        handleConnectCorrectNetwork={() =>
          process.env.REACT_APP_VERCEL_ENV === 'production'
            ? switchNetwork?.(process.env.REACT_APP_MAIN_CHAINID as unknown as number)
            : switchNetwork?.(process.env.REACT_APP_TEST_CHAINID as unknown as number)
        }
      />

      <ConnectWalletModal
        visible={isShowConnectModal}
        bodyChildren={
          <ConnectWalletLists
            handleNextAction={() => {
              modalCtrlDispatch(showConnectModal())
            }}
          />
        }
        onRequestClose={() => modalCtrlDispatch(closeAllModal())}
      />
      <ContainerLayout>
        <Header
          imagePath="/logo/logo.svg"
          isLoading={isLoadingPaper || isConnecting ? true : false}
          isWagmiConnected={isConnected}
          addressOrEnsName={getAddressOrEnsName()}
          // isConnected={auth?.isConnectWallet || false}
          handleBackHome={() => navigate('/')}
          handleConnect={() => modalCtrlDispatch(showConnectModal())}
          handleDisConnect={handleDisConnect}
        />
        {location.pathname !== '/' && (
          <MobileHeader
            isWagmiConnected={isConnected}
            isLoading={isLoadingPaper || isConnecting ? true : false}
            // isConnected={!!auth?.isConnectWallet}
            logoPath="/logo/logo_icon_white.svg"
            currentPath={location.pathname}
            handleBackHome={() => navigate('/')}
            handleBackNavigate={() => navigate(-1)}
            handleConnect={() => modalCtrlDispatch(showConfirmModal())}
            handleDisConnect={handleDisConnect}
          />
        )}
        <Outlet />
      </ContainerLayout>
    </>
  )
}
