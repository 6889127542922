import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { GetSecretValueCommand, SecretsManagerClient } from '@aws-sdk/client-secrets-manager'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import React, { useEffect, useState } from 'react'

const awsClient = new SecretsManagerClient({
  region: process.env.REACT_APP_AWS_SECRET_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? '',
  },
})
const command = new GetSecretValueCommand({ SecretId: process.env.REACT_APP_AWS_SECRET_NAME ?? '' })

export const ApolloWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    ;(async () => {
      const data = await awsClient.send(command)
      const secret = JSON.parse(data.SecretString ?? '{}') as {
        appSyncApiKey: string
        appSyncRegion: string
        appSyncGraphqlUrl: string
      }
      if (!secret.appSyncRegion) {
        console.error('Region Not Found: Add AppSync region to AWS Secrets Manager.')
        return
      }

      if (!secret.appSyncGraphqlUrl) {
        console.error('API URL Not Found: Add AppSync API URL to AWS Secrets Manager.')
        return
      }

      if (!secret.appSyncApiKey) {
        console.error('API KEY Not Found: Add AppSync API KEY to AWS Secrets Manager.')
        return
      }

      const auth = {
        type: 'API_KEY' as const,
        apiKey: secret.appSyncApiKey,
        // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
        // credentials: async () => credentials, // Required when you use IAM-based auth.
      }
      const httpLink = new HttpLink({ uri: secret.appSyncGraphqlUrl })
      const link = ApolloLink.from([
        createAuthLink({ url: secret.appSyncGraphqlUrl, region: secret.appSyncRegion, auth }),
        createSubscriptionHandshakeLink(
          { url: secret.appSyncGraphqlUrl, region: secret.appSyncRegion, auth },
          httpLink
        ),
      ])

      setClient(
        new ApolloClient({
          link,
          cache: new InMemoryCache(),
        })
      )
    })()
  }, [])

  if (!client) return <></>

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
