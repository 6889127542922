import { PaperEmbeddedWalletSdk } from '@paperxyz/embedded-wallet-service-sdk'
import { Chain } from '@paperxyz/sdk-common-utilities'

const validateChain = (value: string | undefined): Chain => {
  const validChains: Chain[] = ['Polygon', 'Mumbai', 'Goerli', 'Ethereum', 'Avalanche']
  if (!value || !validChains.includes(value as Chain)) {
    throw new Error(`Invalid chain type: ${value}`)
  }
  return value as Chain
}

const REQUIRED_ENV_VARS = ['REACT_APP_PAPER_CLIENT', 'REACT_APP_TEST_NETWORKNAME', 'REACT_APP_MAIN_NETWORKNAME']

REQUIRED_ENV_VARS.forEach((envVar) => {
  if (!process.env[envVar]) {
    console.warn(`The environment variable ${envVar} is not set. Please add it to your .env file.`)
  }
})

const paperSDK = new PaperEmbeddedWalletSdk({
  clientId: process.env['REACT_APP_PAPER_CLIENT'] || '',
  chain:
    process.env.REACT_APP_VERCEL_ENV === 'production'
      ? validateChain(process.env.REACT_APP_MAIN_NETWORKNAME)
      : validateChain(process.env.REACT_APP_TEST_NETWORKNAME),
})

export const paperSDKLogin = async () => {
  const result = await paperSDK.auth.loginWithPaperModal()
  return result
}

export const paperSDKGetUser = async () => {
  const result = await paperSDK.getUser()
  return result
}

export const paperSDKLogout = async () => {
  const result = await paperSDK.auth.logout()
  return result
}
