import { useTheme, Address, type ProductProposal } from 'ds_share'
import { StyleSheet, View } from 'react-native'

import { StoreProposalListItem } from './StoreProposalListItem'
import { VoteLoadingProvider } from './VoteLoadingContext'

type StoreProposalListProps = {
  isLoggedIn: boolean
  proposals: ProductProposal[]
  proposalVoteContractAddress: Address
  inCorrectVotingPeriod: boolean
  hasVoted: boolean
}
export const StoreProposalList = ({
  isLoggedIn,
  proposals,
  proposalVoteContractAddress,
  hasVoted,
  inCorrectVotingPeriod,
}: StoreProposalListProps) => {
  const theme = useTheme()

  const styles = StyleSheet.create({
    proposalsTitleContainer: {
      marginTop: theme?.media.isMobile ? 32 : 72,
      paddingLeft: theme?.media.isMobile ? 32 : 0,
    },
    proposalsTitleText: {
      marginTop: 16,
      color: theme?.color.secondaryGray,
      fontSize: theme?.fontSize.md,
      textAlign: 'center', // Center align the text
    },
    keyContainer: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'scroll',
    },
    dateContainer: {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center', // Center align the date text horizontally
    },
    dateText: {
      fontSize: theme?.fontSize.sm,
      color: theme?.color.secondaryGray,
    },
  })
  return (
    <VoteLoadingProvider>
      <View style={styles.keyContainer}>
        {proposals.map((proposal, i) => (
          <StoreProposalListItem
            key={i}
            index={i}
            proposal={proposal}
            hasVoted={hasVoted}
            isLoggedIn={isLoggedIn}
            inCorrectVotingPeriod={inCorrectVotingPeriod}
            proposalVoteContractAddress={proposalVoteContractAddress}
          />
        ))}
      </View>
    </VoteLoadingProvider>
  )
}
