import { ProductProposal, type ProductCandidate, StoreData, StoreDataType, Address } from 'ds_share'
import { abi as voteAbi } from 'ds_share/src/artifacts/contracts/src/core/StoreVote.sol/StoreVoteABI'
import { ethers } from 'ethers'
import { LoaderFunctionArgs } from 'react-router-dom'

const convertProposal = (
  id: string,
  proposalData: [ethers.BigNumberish[], ethers.BigNumberish, ethers.BigNumberish, ethers.BigNumberish]
): ProductProposal => {
  return {
    proposalId: id,
    candidateIds: proposalData[0].map((id) => id.toString()),
    startTime: proposalData[1].toString(),
    endTime: proposalData[2].toString(),
    title: proposalData[3].toString(),
    productCandidates: [],
  }
}

const convertProductCandidate = (
  candidateId: string,
  candidateData: Omit<ProductCandidate, 'candidateId'>
): ProductCandidate => {
  return {
    candidateId,
    candidatedBy: candidateData.candidatedBy as string,
    proposorHandle: candidateData.proposorHandle as string,
    proposorIconImageUrl: candidateData.proposorIconImageUrl as string,
    title: candidateData.title as string,
    cost: Number(candidateData.cost),
    sellPrice: Number(candidateData.sellPrice),
    imageUrl: candidateData.imageUrl as string,
    votedPoints: Number(candidateData.votedPoints),
    voters: candidateData.voters as string[],
    isWinner: candidateData.isWinner as boolean,
    isSold: candidateData.isSold as boolean,
  }
}
export const fetchProposals = async ({ params }: LoaderFunctionArgs): Promise<ProductProposal[] | undefined> => {
  const currentStore = StoreData.data.find((store: StoreDataType) => store.name === params.storeName)
  if (!currentStore) {
    console.info('currentStore is not defined')
    return
  }

  const contractVoteAddress: Address | undefined = currentStore.deployedAddresses?.StoreVote

  if (!contractVoteAddress) {
    console.error('contractVoteAddress is not defined')
    throw new Error('contractVoteAddress is not defined')
  }

  const alchemyProvider = process.env.REACT_APP_ALCHEMY_PROVIDER_URL

  if (!alchemyProvider) {
    throw new Error('alchemyProvider is not defined')
  }

  const provider = new ethers.JsonRpcProvider(alchemyProvider)
  const contract = new ethers.Contract(contractVoteAddress, voteAbi, provider)

  let proposalIds: ethers.BigNumberish

  switch (params.proposalByWeek) {
    case 'this-week':
      proposalIds = await contract.getProposalsThisWeek()
      break
    case 'last-week':
      proposalIds = await contract.getProposalsLastWeek()
      break
    default:
      throw new Error('Invalid week parameter')
  }

  const proposalsThisWeek: ProductProposal[] = []

  for (const id of proposalIds as unknown as string[]) {
    const proposalData = await contract.getProposal(id)
    const proposal = await convertProposal(String(id), proposalData)

    const productCandidateDatas = await Promise.all(
      proposal.candidateIds.map((candidateId) => contract.getProductCandidate(candidateId))
    )

    for (const [index, candidateData] of productCandidateDatas.entries()) {
      const candidate = await convertProductCandidate(proposal.candidateIds[index], candidateData)
      proposal.productCandidates.push(candidate)
    }

    proposalsThisWeek.push(proposal)
  }
  return await proposalsThisWeek
}
