import { useTheme, Image, Text, Address, type ProductCandidate, type ProductProposal } from 'ds_share'
import { useMemo } from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'

import { ProposalVote } from './ProposalVote'

interface StoreProposalListItemProps {
  index: number
  proposal: ProductProposal
  isLoggedIn: boolean
  proposalVoteContractAddress: Address
  inCorrectVotingPeriod: boolean
  hasVoted: boolean
}

export const StoreProposalListItem = ({
  index,
  proposal,
  isLoggedIn,
  proposalVoteContractAddress,
  inCorrectVotingPeriod,
  hasVoted,
}: StoreProposalListItemProps) => {
  const theme = useTheme()
  const windowWidth = useWindowDimensions().width

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginLeft: index > 0 ? 10 : 0,
        padding: 16,
        borderRadius: 16,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
      card: {
        flexBasis: windowWidth > 700 ? '22%' : '45%',
        marginBottom: 20,
        paddingBottom: 8,
        borderRadius: 8,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
      },
      keyImage: {
        width: '100%',
        aspectRatio: 1,
        padding: 4,
        resizeMode: 'cover',
      },
      title: {
        color: theme?.color.primaryBlack,
        fontSize: theme?.fontSize.md,
      },
      // price: {
      //   marginTop: 4,
      //   color: theme?.color.primaryBlack,
      //   fontSize: theme?.fontSize.xl,
      // },
      voteWrapper: {
        alignSelf: 'center',
        borderRadius: 4,
        borderColor: theme?.color.secondaryLiteGray,
        padding: 4,
        backgroundColor: '#fff',
      },
      voteText: {
        color: theme?.color.primaryBlack,
        fontSize: theme?.fontSize.sm,
      },
      proposerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        paddingVertical: 10,
        paddingHorizontal: 16,
      },

      proposerHandle: {
        color: theme?.color.primaryBlack,
        fontSize: theme?.fontSize.xs,
        justifyContent: 'flex-start',
        flex: 1,
        flexWrap: 'wrap',
        marginLeft: 10,
      },
      proposerIcon: {
        width: 36,
        height: 36,
        borderRadius: 20,
        marginRight: 4,
        resizeMode: 'cover',
      },
    })
  }, [theme, windowWidth, index])

  return (
    <View style={styles.container}>
      {proposal.productCandidates.map((productCandidate: ProductCandidate) => (
        <View style={styles.card} key={productCandidate.candidateId}>
          <Image source={{ uri: productCandidate.imageUrl }} style={styles.keyImage} />
          <View style={styles.proposerContainer}>
            <Image
              source={{
                uri: productCandidate.proposorIconImageUrl,
              }}
              style={styles.proposerIcon}
              onError={(error) => console.error('Image of  loading error:', error)}
            />
            <Text style={styles.proposerHandle} numberOfLines={2} ellipsizeMode="tail">
              Proposed by {productCandidate.proposorHandle}
            </Text>
          </View>
          <Text style={styles.title} numberOfLines={2} ellipsizeMode="tail">
            {productCandidate.title}
          </Text>
          {/* <Text style={styles.price}>${productCandidate.sellPrice} USD</Text> */}
          <View style={styles.voteWrapper}>
            <Text style={styles.voteText}>{productCandidate.votedPoints} pt</Text>
          </View>
          <ProposalVote
            proposalId={proposal.proposalId}
            candidateId={productCandidate.candidateId}
            isLoggedIn={isLoggedIn}
            proposalVoteContractAddress={proposalVoteContractAddress}
            inCorrectVotingPeriod={inCorrectVotingPeriod}
            hasVoted={hasVoted}
          />
        </View>
      ))}
    </View>
  )
}
