import React, { ReactNode, createContext, useContext, useState } from 'react'

interface VoteLoadingContextProps {
  isVoteLoading: boolean
  handleVoteLoadingStart: () => void
  handleVoteLoadingEnd: () => void
}

interface VoteLoadingProviderProps {
  children: ReactNode
}

const VoteLoadingContext = createContext<VoteLoadingContextProps | null>(null)

export const useVoteLoading = () => {
  const context = useContext(VoteLoadingContext)
  if (!context) throw new Error('useVoteLoading must be used within a VoteLoadingProvider')
  return context
}

export const VoteLoadingProvider = ({ children }: VoteLoadingProviderProps) => {
  const [isVoteLoading, setIsVoteLoading] = useState(false)

  function handleVoteLoadingStart() {
    setIsVoteLoading(true)
  }

  function handleVoteLoadingEnd() {
    setIsVoteLoading(false)
  }

  return (
    <VoteLoadingContext.Provider value={{ isVoteLoading, handleVoteLoadingStart, handleVoteLoadingEnd }}>
      {children}
    </VoteLoadingContext.Provider>
  )
}
