import { ContractCallInputType, UserStatus } from '@paperxyz/embedded-wallet-service-sdk'
import {
  useTheme,
  PrimaryButton,
  useAppDispatch,
  showConfirmModal,
  Address,
  Button,
  useAppSelector,
  RootState,
} from 'ds_share'
import { useMemo, useState, useEffect } from 'react'
import { StyleSheet, useWindowDimensions } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { useAccount, useContractWrite, useWaitForTransaction } from 'wagmi'

import { useToast } from '../../hooks/useToast'
import { paperSDKGetUser } from '../../lib/paperSDK'
import { getWagmiConfig } from '../../repository/contracts/wagmiConfig'

import { useVoteLoading } from './VoteLoadingContext'

type ProposalVoteButtonProps = {
  proposalId: string
  candidateId: string
  isLoggedIn: boolean
  proposalVoteContractAddress: Address
  inCorrectVotingPeriod: boolean
  hasVoted: boolean
}

export const ProposalVote = ({
  proposalId,
  candidateId,
  isLoggedIn,
  proposalVoteContractAddress,
  inCorrectVotingPeriod,
  hasVoted,
}: ProposalVoteButtonProps) => {
  const { isVoteLoading, handleVoteLoadingStart, handleVoteLoadingEnd } = useVoteLoading()
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const windowWidth = useWindowDimensions().width
  const wagmiConfig = getWagmiConfig(proposalVoteContractAddress, 'vote')
  const [handleVotingLoadingOfPaper, setHandleVotingLoadingOfPaper] = useState(false)
  const { data, error, isLoading, write } = useContractWrite(wagmiConfig)
  const { showErrorToast, showSuccessToast } = useToast()
  const { isConnected } = useAccount()
  const isConnectPaperWallet: boolean = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const [reload, setReload] = useState(false)

  const txResult = useWaitForTransaction({ hash: data?.hash })
  const navigate = useNavigate()
  useEffect(() => {
    if (txResult?.status === 'success') {
      showSuccessToast('Your voting is completed.')
      setReload((prev) => !prev)
      navigate(0)
    }
  }, [txResult?.status])

  useEffect(() => {
    setReload(false)
  }, [reload])

  useEffect(() => {
    if (handleVotingLoadingOfPaper || isLoading) {
      handleVoteLoadingStart()
    } else {
      handleVoteLoadingEnd()
    }
  }, [handleVotingLoadingOfPaper, isLoading])

  const styles = useMemo(() => {
    return StyleSheet.create({
      voteButton: {
        marginTop: 16,
        paddingHorizontal: 16,
        paddingVertical: 8,
      },
    })
  }, [theme, windowWidth])

  async function voteToProposal(proposalId: string, candidateId: string, proposalVoteContractAddress: Address) {
    const NO_PAPER_WALLET_ERROR = 'Something wrong with paperwallet'
    const VOTING_SUCCESS_MESSAGE = 'Success! Your vote has been submitted.'
    const GASLESS_PAPER_TRANSACTION_ERROR = 'something went wrong sending gasless transaction'

    try {
      if (isConnected) {
        write({ args: [proposalId, candidateId] })
      } else if (isConnectPaperWallet) {
        const userResponse = await paperSDKGetUser()
        if (userResponse.status !== UserStatus.LOGGED_IN_WALLET_INITIALIZED) {
          throw new Error(NO_PAPER_WALLET_ERROR)
        }

        const params: ContractCallInputType = {
          contractAddress: proposalVoteContractAddress,
          methodArgs: [proposalId, candidateId],
          methodInterface: 'function vote(uint256,uint256)',
        }

        const { transactionHash } = await userResponse.wallet.gasless.callContract(params)
        console.info('voting success', transactionHash)
        if (transactionHash) {
          showSuccessToast(VOTING_SUCCESS_MESSAGE)
          navigate(0)
        }
      } else {
        console.error('not connected')
      }
    } catch (e: string | unknown) {
      console.error(`${GASLESS_PAPER_TRANSACTION_ERROR} ${e}`)
      showErrorToast(e as string)
    } finally {
      setHandleVotingLoadingOfPaper(false)
      setReload((prev) => !prev)
    }
  }

  if (isLoading || handleVotingLoadingOfPaper) {
    return <Button title="Voting..." />
  }

  if (hasVoted || inCorrectVotingPeriod || isVoteLoading) return null

  return (
    <>
      {error && showErrorToast(error.message)}

      <PrimaryButton
        customStyle={styles.voteButton}
        title="Vote"
        onPress={() => {
          if (isLoggedIn) {
            voteToProposal(proposalId, candidateId, proposalVoteContractAddress)
            showSuccessToast('Your voting is started.')
            setHandleVotingLoadingOfPaper(true)
          } else {
            dispatch(showConfirmModal())
            showErrorToast('Sorry, Please connect wallet')
          }
        }}
      />
    </>
  )
}
