import { Toasts } from 'ds_share'
import { Outlet } from 'react-router-dom'

export const AuthenticationLayout = () => {
  return (
    <>
      <Outlet />
      <Toasts />
    </>
  )
}
