import { showToast, useAppDispatch } from 'ds_share'

export function extractReasonForToast(jsonString: string): string {
  console.info('extractReasonForToast log:', jsonString)
  if (typeof jsonString === 'string') {
    return jsonString.length <= 40 ? jsonString : jsonString.substr(0, 40) + '...'
  }
  try {
    const obj = JSON.parse(jsonString)
    const reason = obj.reason || obj.detail || 'Error: unknown'
    return reason.length <= 40 ? reason : reason.substr(0, 40) + '...'
  } catch (error) {
    console.error('Failed to parse the JSON string:', error)
    return 'Error: unknown'
  }
}

export const useToast = () => {
  const dispatch = useAppDispatch()

  const showErrorToast = (error_message: string) => {
    dispatch(showToast({ message: extractReasonForToast(error_message), type: 'error', status: 'ERROR' }))
  }

  const showSuccessToast = (message: string) => {
    dispatch(showToast({ message: message, type: 'success', status: 'SUCCESS' }))
  }

  return {
    showErrorToast,
    showSuccessToast,
  }
}
