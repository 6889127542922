"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  ActiveEventItem: () => ActiveEventItem,
  ActiveEventsList: () => ActiveEventsList,
  AppsModal: () => AppsModal,
  AppsSlide: () => AppsSlide,
  AuthorizationSubHeaderForProposal: () => AuthorizationSubHeaderForProposal,
  AuthorizedByNFTHeader: () => AuthorizedByNFTHeader,
  BrandSalesRankingData: () => BrandSalesRankingData,
  Button: () => Button,
  ButtonWithChildren: () => ButtonWithChildren,
  ConnectWalletModal: () => ConnectWalletModal,
  ContainerLayout: () => ContainerLayout,
  DashboardIndicators: () => DashboardIndicators,
  DashboardTabview: () => DashboardTabview,
  DeStoreThemeProvider: () => DeStoreThemeProvider,
  DeployedContractItem: () => DeployedContractItem,
  DeployedContractsList: () => DeployedContractsList,
  ExploreDeStoreModal: () => ExploreDeStoreModal,
  Header: () => Header,
  HomeCta: () => HomeCta,
  HomeEnterDeStoreSection: () => HomeEnterDeStoreSection,
  HomeExploreDeStoreSection: () => HomeExploreDeStoreSection,
  Image: () => Image,
  InitSpinner: () => InitSpinner,
  KeyData: () => KeyData,
  LineItemData: () => LineItemData,
  Link: () => Link,
  LiveHeaderCenterChildren: () => LiveHeaderCenterChildren,
  LiveLineItems: () => LiveLineItems,
  LiveSalesInfoItem: () => LiveSalesInfoItem,
  LiveSalesInfoItems: () => LiveSalesInfoItems,
  MobileHeader: () => MobileHeader,
  Modal: () => Modal,
  NetworkDifferentModal: () => NetworkDifferentModal,
  OutlineButton: () => OutlineButton,
  PrimaryButton: () => PrimaryButton,
  ProfileData: () => ProfileData,
  ProposalMenuAppData: () => ProposalMenuAppData,
  ProposalRequirementSection: () => ProposalRequirementSection,
  ProposalsUserRequirementSection: () => ProposalsUserRequirementSection,
  SalesAndExpenseData: () => SalesAndExpenseData,
  SettingsHeaderCenterChildren: () => SettingsHeaderCenterChildren,
  SettingsItem: () => SettingsItem,
  SettingsItems: () => SettingsItems,
  SettingsItemsPropsData: () => SettingsItemsPropsData,
  Spacer: () => Spacer,
  Spinner: () => Spinner,
  StoreData: () => StoreData,
  StoreHeaderTitle: () => StoreHeaderTitle,
  StoreIndicatorsData: () => StoreIndicatorsData,
  StoreKeyBaseInfo: () => StoreKeyBaseInfo,
  StoreKeyDescriptionItem: () => StoreKeyDescriptionItem,
  StoreKeyList: () => StoreKeyList,
  StoreKeyListItem: () => StoreKeyListItem,
  StoreListItem: () => StoreListItem,
  StoreLiveStreamingData: () => StoreLiveStreamingData,
  StoreMenuAppData: () => StoreMenuAppData,
  StoreNFTsBaseInfo: () => StoreNFTsBaseInfo,
  StoreNFTsVisualImage: () => StoreNFTsVisualImage,
  StoreSalesInfoData: () => StoreSalesInfoData,
  TabGroupSpacer: () => TabGroupSpacer,
  TabOverlay: () => TabOverlay,
  Text: () => Text3,
  Toasts: () => Toasts,
  WalletConfirmModal: () => WalletConfirmModal,
  closeAllModal: () => closeAllModal,
  connectWallet: () => connectWallet,
  disconnectWallet: () => disconnectWallet,
  hideToast: () => hideToast,
  paperAuthLoadingEnd: () => paperAuthLoadingEnd,
  paperAuthLoadingStart: () => paperAuthLoadingStart,
  showConfirmModal: () => showConfirmModal,
  showConnectModal: () => showConnectModal,
  showToast: () => showToast,
  store: () => store,
  storeZeroAddresses: () => storeZeroAddresses,
  useAppDispatch: () => useAppDispatch,
  useAppSelector: () => useAppSelector,
  useLinking: () => useLinking,
  useMediaQuery: () => useMediaQuery,
  useTheme: () => useTheme
});
module.exports = __toCommonJS(src_exports);

// src/globalStore/index.tsx
var import_toolkit4 = require("@reduxjs/toolkit");
var import_react_redux = require("react-redux");

// src/globalStore/auth.tsx
var import_toolkit = require("@reduxjs/toolkit");
var authSlice = (0, import_toolkit.createSlice)({
  name: "auth",
  initialState: {
    isConnectPaperWallet: false,
    isPaperAuthLoading: false,
    connectedPaperWallet: null,
    ownedDeStoreNFT: []
  },
  reducers: {
    paperAuthLoadingStart: (state) => {
      state.isPaperAuthLoading = true;
    },
    connectWallet: (state, action) => {
      const { authType, walletAddress } = action.payload;
      state.isConnectPaperWallet = true;
      state.connectedPaperWallet = {
        authType,
        walletAddress
      };
      state.isPaperAuthLoading = false;
    },
    disconnectWallet: (state) => {
      if (!state.isConnectPaperWallet || state.connectedPaperWallet === null) {
        console.log("Error: disconnectWallet but isConnectWallet is false");
      } else {
        state.isConnectPaperWallet = false;
        state.connectedPaperWallet = null;
        state.isPaperAuthLoading = false;
      }
    },
    paperAuthLoadingEnd: (state) => {
      state.isPaperAuthLoading = false;
    }
  }
});
var { paperAuthLoadingStart, connectWallet, disconnectWallet, paperAuthLoadingEnd } = authSlice.actions;
var auth_default = authSlice;

// src/globalStore/modalCtrl.tsx
var import_toolkit2 = require("@reduxjs/toolkit");
var modalCtrlSlice = (0, import_toolkit2.createSlice)({
  name: "modalCtrl",
  initialState: {
    isShowConfirmModal: false,
    isShowConnectModal: false
  },
  reducers: {
    showConfirmModal: (state) => {
      state.isShowConfirmModal = true;
      state.isShowConnectModal = false;
    },
    showConnectModal: (state) => {
      state.isShowConnectModal = true;
      state.isShowConfirmModal = false;
    },
    closeAllModal: (state) => {
      state.isShowConfirmModal = false;
      state.isShowConnectModal = false;
    }
  }
});
var { showConfirmModal, showConnectModal, closeAllModal } = modalCtrlSlice.actions;
var modalCtrl_default = modalCtrlSlice;

// src/globalStore/toast.tsx
var import_toolkit3 = require("@reduxjs/toolkit");
var toastSlice = (0, import_toolkit3.createSlice)({
  name: "toast",
  initialState: {
    toasts: []
  },
  reducers: {
    showToast: (state, action) => {
      state.toasts = [action.payload, ...state.toasts];
    },
    hideToast: (state, action) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    }
  }
});
var { showToast, hideToast } = toastSlice.actions;
var toast_default = toastSlice;

// src/globalStore/index.tsx
var store = (0, import_toolkit4.configureStore)({
  reducer: {
    [auth_default.name]: auth_default.reducer,
    [modalCtrl_default.name]: modalCtrl_default.reducer,
    [toast_default.name]: toast_default.reducer
  }
});
var useAppDispatch = () => (0, import_react_redux.useDispatch)();
var useAppSelector = import_react_redux.useSelector;

// src/constants/deployedContractAddress.ts
var MUMBAI_TEST_StoreZeroVoteAddress = "0xc73eaa2F8e5D3A017C246b6CF3e9Dd4fC443e1AA";
var MUMBAI_TEST_StoreManagerAddress = "0x2eb5519c3b35Da6308506092EFc93C2307f3b35a";
var MUMBAI_TEST_StoreTreasuryAddress = "0xA1e470446DE93E87285864Da4C625023bDb7f82D";
var POLYGON_MAIN_StoreZeroManagerAddress = "0xC87738Be56f29a0a2872De046cb4e3dEB9f9aA65";
var POLYGON_MAIN_StoreZeroVoteAddress = "0xa304504f942A0ba5397d88de9ee2CE634AdBd2a7";
var POLYGON_MAIN_StoreZeroTrasuryAddress = "0xA1e470446DE93E87285864Da4C625023bDb7f82D";
var storeZeroAddresses = () => {
  if (process.env["REACT_APP_VERCEL_ENV"] === "production") {
    return {
      VOTE_CONTRACT_ADDRESS: POLYGON_MAIN_StoreZeroVoteAddress,
      STORE_MANAGER_ADDRESS: POLYGON_MAIN_StoreZeroManagerAddress,
      TREASURY_ADDRESS: POLYGON_MAIN_StoreZeroTrasuryAddress
    };
  } else {
    return {
      VOTE_CONTRACT_ADDRESS: MUMBAI_TEST_StoreZeroVoteAddress,
      STORE_MANAGER_ADDRESS: MUMBAI_TEST_StoreManagerAddress,
      TREASURY_ADDRESS: MUMBAI_TEST_StoreTreasuryAddress
    };
  }
};

// src/components/ui/Button.tsx
var import_react4 = require("react");
var import_react_native3 = require("react-native");

// src/hooks/useLinking.ts
var import_react = require("react");
var import_react_native = require("react-native");
var prefix = "destore://destore";
var useLinking = () => {
  const handleLinking = (0, import_react.useCallback)(async (url) => {
    if (import_react_native.Platform.OS === "web")
      throw new Error("Linking must be used in native");
    if (!url)
      throw new Error("Url is required in Linking");
    const supported = await import_react_native.Linking.canOpenURL(url);
    if (!supported)
      throw new Error("The specified url is not supported");
    return import_react_native.Linking.openURL(prefix + url);
  }, []);
  return { handleLinking };
};

// src/hooks/useMediaQuery.ts
var import_react_native2 = require("react-native");

// src/theme/index.ts
var DeStoreColor = {
  dark: "#161f29",
  // background
  primaryBlack: "#161f29",
  // text primary
  secondaryWhite: "#ffffff",
  // text secondary
  secondaryLight: "#f6f6f6",
  // text secondary light
  gray: "#b7b7b7",
  // gray or border
  secondaryGray: "#67686A",
  // gray or border light
  secondaryLiteGray: "#C5C8CC",
  // gray or border more lighter
  secondaryLightGreen: "#E4EFE6",
  mint: "#bdfdc7",
  // mint, ex) primary button background
  purple: "#d8c9f6",
  pink: "#ffa0a0",
  black: "#000000"
};
var DeStoreFontSize = {
  xxs: 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  "2xl": 24,
  "3xl": 28,
  "4xl": 36,
  "5xl": 40,
  "6xl": 48
};
var DeStoreRadius = {
  none: 0,
  sm: 2,
  base: 4,
  md: 6,
  lg: 8,
  full: 999
};
var DeStoreBreakPoint = {
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280
};
var DeStoreTheme = {
  color: DeStoreColor,
  fontSize: DeStoreFontSize,
  radius: DeStoreRadius,
  breakpoint: DeStoreBreakPoint,
  media: {
    isMobile: false,
    isTablet: false,
    isDesktop: false
  }
};

// src/hooks/useMediaQuery.ts
var useMediaQuery = () => {
  const { width } = (0, import_react_native2.useWindowDimensions)();
  const isMobile = width < DeStoreBreakPoint.md;
  const isTablet = DeStoreBreakPoint.md <= width && width <= DeStoreBreakPoint.lg;
  const isDesktop = DeStoreBreakPoint.lg < width;
  return { isMobile, isTablet, isDesktop };
};

// src/hooks/useTheme.ts
var import_react3 = require("react");

// src/theme/ThemeProvider.tsx
var import_react2 = require("react");
var import_jsx_runtime = require("react/jsx-runtime");
var ThemeStateContext = (0, import_react2.createContext)(void 0);
var DeStoreThemeProvider = ({ children }) => {
  const { isMobile, isTablet, isDesktop } = useMediaQuery();
  const theme = (0, import_react2.useMemo)(() => {
    return {
      ...DeStoreTheme,
      media: {
        ...DeStoreTheme.media,
        isMobile,
        isTablet,
        isDesktop
      }
    };
  }, [isMobile, isTablet, isDesktop]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ThemeStateContext.Provider, { value: theme, children });
};

// src/hooks/useTheme.ts
var useTheme = () => {
  return (0, import_react3.useContext)(ThemeStateContext);
};

// src/components/ui/Button.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var DefaultButton = ({
  title,
  subText,
  textColor,
  buttonColor,
  pressableColor,
  isOutline = false,
  customStyle,
  ...props
}) => {
  const [isHover, setIsHover] = (0, import_react4.useState)(false);
  const theme = useTheme();
  const handleHoverIn = (0, import_react4.useCallback)(() => {
    setIsHover(true);
  }, []);
  const handleHoverOut = (0, import_react4.useCallback)(() => {
    setIsHover(false);
  }, []);
  const styles3 = (0, import_react4.useMemo)(() => {
    return import_react_native3.StyleSheet.create({
      pressable: {
        paddingHorizontal: 40,
        paddingVertical: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: isOutline ? pressableColor : isHover ? "transparent" : "transparent",
        borderRadius: theme?.radius.full,
        color: isHover ? theme?.color.primaryBlack : textColor,
        backgroundColor: isHover ? pressableColor : buttonColor,
        opacity: isOutline ? 1 : isHover ? 0.7 : 1
      },
      pressablePressed: {
        backgroundColor: pressableColor,
        opacity: 0.7
      },
      text: {
        color: "inherit",
        fontWeight: "bold",
        textAlign: "center"
      },
      subText: {
        color: "gray",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile, pressableColor, isHover]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
    import_react_native3.Pressable,
    {
      style: (state) => [styles3.pressable, customStyle, state.pressed && styles3.pressablePressed],
      onHoverIn: () => {
        props.onHoverIn, handleHoverIn();
      },
      onHoverOut: () => {
        props.onHoverOut, handleHoverOut();
      },
      ...props,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react_native3.Text, { style: styles3.text, children: title }),
        subText && /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react_native3.Text, { style: styles3.subText, children: subText })
      ]
    }
  );
};
var Button = ({ title, textColor, buttonColor, ...props }) => {
  const theme = useTheme();
  const txtColor = textColor || theme?.color.secondaryWhite || "#000000";
  const btnColor = buttonColor || theme?.color.mint || "#ffffff";
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(DefaultButton, { title, textColor: txtColor, buttonColor: btnColor, pressableColor: btnColor, ...props });
};
var PrimaryButton = ({ title, textColor, buttonColor, pressableColor, ...props }) => {
  const theme = useTheme();
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    DefaultButton,
    {
      title,
      textColor: textColor || theme?.color.primaryBlack || "",
      buttonColor: buttonColor || theme?.color.mint || "",
      pressableColor: pressableColor || theme?.color.mint || "",
      ...props
    }
  );
};
var OutlineButton = ({
  title,
  subText,
  textColor,
  buttonColor,
  pressableColor,
  ...props
}) => {
  const theme = useTheme();
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    DefaultButton,
    {
      title,
      subText,
      textColor: textColor || theme?.color.secondaryWhite || "",
      buttonColor: buttonColor || "transparent",
      pressableColor: pressableColor || theme?.color.mint || "",
      isOutline: true,
      ...props
    }
  );
};
var ButtonWithChildren = ({ children, buttonColor, customStyle, ...props }) => {
  const [isHover, setIsHover] = (0, import_react4.useState)(false);
  const theme = useTheme();
  const handleHoverIn = (0, import_react4.useCallback)(() => {
    setIsHover(true);
  }, []);
  const handleHoverOut = (0, import_react4.useCallback)(() => {
    setIsHover(false);
  }, []);
  const styles3 = (0, import_react4.useMemo)(() => {
    return import_react_native3.StyleSheet.create({
      pressable: {
        paddingHorizontal: 40,
        paddingVertical: 16,
        borderRadius: theme?.radius.full,
        color: theme?.color.primaryBlack,
        backgroundColor: buttonColor,
        opacity: isHover ? 0.7 : 1
      },
      pressablePressed: {
        backgroundColor: buttonColor,
        opacity: 0.7
      },
      text: {
        color: "inherit",
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile, buttonColor, isHover]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    import_react_native3.Pressable,
    {
      style: (state) => [styles3.pressable, customStyle, state.pressed && styles3.pressablePressed],
      onHoverIn: () => {
        props.onHoverIn, handleHoverIn();
      },
      onHoverOut: () => {
        props.onHoverOut, handleHoverOut();
      },
      ...props,
      children
    }
  );
};

// src/components/ui/Image.tsx
var import_react_native4 = require("react-native");
var import_jsx_runtime3 = require("react/jsx-runtime");
var Image = ({ ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_react_native4.Image, { ...props });
};

// src/components/ui/Link.tsx
var import_react_native5 = require("react-native");
var import_react_router_dom = require("react-router-dom");
var import_jsx_runtime4 = require("react/jsx-runtime");
var Link = ({ children, customStyle, webStyle, ...props }) => {
  const theme = useTheme();
  const styles3 = import_react_native5.StyleSheet.create({
    link: {
      textDecorationColor: theme?.color.primaryBlack,
      color: theme?.color.primaryBlack
    }
  });
  if (import_react_native5.Platform.OS === "web" && !["http://", "https://"].includes(props.href)) {
    return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(import_react_router_dom.Link, { style: webStyle, to: props.href, role: "link", children });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(import_react_native5.Text, { style: [styles3.link, customStyle], accessibilityRole: props.accessibilityRole || "link", ...props, children });
};

// src/components/ui/Modal.tsx
var import_react5 = require("react");
var import_fi = require("react-icons/fi");
var import_react_native7 = require("react-native");

// src/components/ui/Spacer.tsx
var import_react_native6 = require("react-native");
var import_jsx_runtime5 = require("react/jsx-runtime");
var Spacer = ({ direction, space }) => {
  const styles3 = import_react_native6.StyleSheet.create({
    spacer: {
      width: direction === "vertical" ? space : "100%",
      height: direction === "horizontal" ? space : "100%"
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_react_native6.View, { style: styles3.spacer });
};

// src/components/ui/Modal.tsx
var import_jsx_runtime6 = require("react/jsx-runtime");
var Modal = ({
  maxWidth = 576,
  width = "90%",
  background = "secondaryWhite",
  animationType = "fade",
  transparent = true,
  headerChildren,
  bodyChildren,
  footerChildren,
  ...props
}) => {
  const { onRequestClose } = props;
  const theme = useTheme();
  const styles3 = (0, import_react5.useMemo)(() => {
    return import_react_native7.StyleSheet.create({
      overlay: {
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "0px 0px 1px 4px rgba(0, 0, 0, 0.25)"
      },
      container: {
        maxWidth,
        width,
        margin: "auto",
        paddingVertical: 40,
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        borderRadius: theme?.radius.lg,
        backgroundColor: theme?.color[background],
        position: "relative"
      },
      fixIcon: {
        width: "24px",
        position: "absolute",
        top: 16,
        right: 16
      },
      header: {
        width: "100%"
      },
      body: {
        width: "100% "
      },
      footer: {
        width: "100%"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_react_native7.Modal, { animationType, transparent, ...props, children: [
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.Pressable, { style: styles3.overlay, onPress: onRequestClose }),
    /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_react_native7.View, { style: styles3.container, children: [
      import_react_native7.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.Pressable, { style: styles3.fixIcon, onPress: onRequestClose, children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_fi.FiX, { color: theme?.color.primaryBlack, size: 24 }) }) : null,
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.View, { style: styles3.header, children: headerChildren }),
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(Spacer, { direction: "horizontal", space: 12 }),
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.View, { style: styles3.body, children: bodyChildren }),
      footerChildren && /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_jsx_runtime6.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(Spacer, { direction: "horizontal", space: 32 }),
        /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.View, { style: styles3.footer, children: footerChildren })
      ] })
    ] })
  ] });
};

// src/components/ui/Spinner.tsx
var import_react_native8 = require("react-native");
var import_jsx_runtime7 = require("react/jsx-runtime");
var Spinner = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_react_native8.View, { style: styles.container, children: /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_react_native8.Image, { source: { uri: "/key/key_rainbow.gif" }, style: styles.image, resizeMode: "contain" }) });
};
var InitSpinner = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_react_native8.View, { style: styles.view, children: /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(Spinner, {}) });
};
var styles = import_react_native8.StyleSheet.create({
  view: {
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    width: 160,
    height: 160
  },
  image: {
    width: "80%",
    height: "80%"
  }
});

// src/components/ui/Text.tsx
var import_react_native9 = require("react-native");
var import_jsx_runtime8 = require("react/jsx-runtime");
var Text3 = ({ children, ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_react_native9.Text, { ...props, children });
};

// src/components/ui/Toast.tsx
var import_react6 = require("react");
var import_react_native10 = require("react-native");
var import_jsx_runtime9 = require("react/jsx-runtime");
var Toast = ({ ...props }) => {
  const { id, message, description, status } = props;
  const opacity = (0, import_react6.useMemo)(() => new import_react_native10.Animated.Value(0), []);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  (0, import_react6.useEffect)(() => {
    const timerId = setTimeout(() => {
      dispatch(hideToast(id));
    }, 5200);
    return () => {
      clearTimeout(timerId);
    };
  }, [dispatch]);
  (0, import_react6.useEffect)(() => {
    import_react_native10.Animated.timing(opacity, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true
    }).start();
    const timerId = setTimeout(() => {
    }, 5200);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  const TOAST_COLOR = {
    SUCCESS: theme?.color.mint,
    ERROR: theme?.color.pink
  };
  const TEXT_COLOR = {
    SUCCESS: theme?.color.primaryBlack,
    ERROR: theme?.color.secondaryLight
  };
  console.log("status", status);
  const styles3 = (0, import_react6.useMemo)(
    () => import_react_native10.StyleSheet.create({
      container: {
        minWidth: 280,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        backgroundColor: TOAST_COLOR[status],
        color: TEXT_COLOR[status]
      },
      title: {
        fontSize: 18,
        fontWeight: "bold",
        color: TEXT_COLOR[status]
      },
      description: {
        fontSize: 14,
        color: theme?.color.secondaryLiteGray
      }
    }),
    [status]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(import_react_native10.Animated.View, { style: [{ opacity }, styles3.container], children: [
    /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Text3, { style: styles3.title, children: message }),
    description ? /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Text3, { style: styles3.description, children: description }) : null
  ] });
};
var Toasts = () => {
  const theme = useTheme();
  const toasts = useAppSelector((state) => state.toast.toasts);
  const styles3 = import_react_native10.StyleSheet.create({
    container: {
      position: "absolute",
      top: theme?.media.isMobile ? 20 : 100,
      right: theme?.media.isMobile ? 20 : 80,
      zIndex: 10
    },
    toastContainer: { marginTop: 10 }
  });
  if (toasts.length === 0)
    return null;
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_react_native10.View, { style: styles3.container, children: toasts.map((toast, i) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_react_native10.View, { style: i !== 0 ? styles3.toastContainer : null, children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Toast, { ...toast }) }, toast.id)) });
};

// src/components/common/AppsModal.tsx
var import_react9 = require("react");
var import_react_native13 = require("react-native");

// src/components/common/AppCards.tsx
var import_react8 = require("react");
var import_react_native12 = require("react-native");

// src/components/common/AppCard.tsx
var import_react7 = require("react");
var import_react_native11 = require("react-native");
var import_jsx_runtime10 = require("react/jsx-runtime");
var AppCard = ({ imagePath, name, description, upRightArrowImagePath }) => {
  const theme = useTheme();
  const styles3 = (0, import_react7.useMemo)(() => {
    return import_react_native11.StyleSheet.create({
      container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      },
      iconAndNameContainer: {
        flexDirection: "row",
        alignItems: "center",
        gap: 24
      },
      image: {
        width: 60,
        height: 60
      },
      nameContainer: {
        maxWidth: theme?.media.isMobile ? 165 : 233,
        minWidth: theme?.media.isMobile ? 165 : 233,
        gap: 4
      },
      name: {
        color: theme?.color.secondaryWhite,
        fontSize: 14,
        lineHeight: 18
      },
      description: {
        color: theme?.color.secondaryWhite,
        opacity: 0.5,
        fontSize: 12,
        lineHeight: 16
      },
      upRightArrowButton: {
        width: 38,
        height: 38,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 48,
        alignItems: "center",
        justifyContent: "center"
      },
      upRightArrowImage: {
        width: 22,
        height: 22
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_react_native11.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_react_native11.View, { style: styles3.iconAndNameContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Image, { source: { uri: imagePath }, style: styles3.image }),
      /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_react_native11.View, { style: styles3.nameContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Text3, { style: styles3.name, children: name }),
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Text3, { style: styles3.description, children: description })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.upRightArrowButton,
        buttonColor: theme?.color.secondaryWhite + "1A",
        children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Image, { source: { uri: upRightArrowImagePath }, style: styles3.upRightArrowImage })
      }
    )
  ] });
};

// src/components/common/AppCards.tsx
var import_jsx_runtime11 = require("react/jsx-runtime");
var AppCards = ({ appCards }) => {
  const styles3 = (0, import_react8.useMemo)(() => {
    return import_react_native12.StyleSheet.create({
      container: {
        width: "100%",
        gap: 24
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(import_react_native12.View, { style: styles3.container, children: appCards.map((appCard) => /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(AppCard, { ...appCard }, appCard.name)) });
};

// src/components/common/AppsModal.tsx
var import_jsx_runtime12 = require("react/jsx-runtime");
var ModalBody = ({ appCards }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(AppCards, { appCards });
};
var AppsModal = (props) => {
  const { appCards, close } = props;
  const theme = useTheme();
  const styles3 = (0, import_react9.useMemo)(() => {
    return import_react_native13.StyleSheet.create({
      outside: {
        flex: 1,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
      },
      container: {
        padding: 24,
        borderRadius: 16,
        backgroundColor: theme?.color.primaryBlack,
        maxWidth: theme?.media.isMobile ? void 0 : 427
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_jsx_runtime12.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.Modal, { animationType: "fade", ...props, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.TouchableOpacity, { style: styles3.outside, onPress: close, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.TouchableWithoutFeedback, { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(ModalBody, { appCards }) }) }) }) }) });
};

// src/components/common/AppsSlide.tsx
var import_react10 = require("react");
var import_io5 = require("react-icons/io5");
var import_react_native14 = require("react-native");
var import_jsx_runtime13 = require("react/jsx-runtime");
var AppsSlide = ({ close, appCards, ...props }) => {
  const theme = useTheme();
  const styles3 = (0, import_react10.useMemo)(() => {
    return import_react_native14.StyleSheet.create({
      outside: {
        flex: 1,
        width: "100%",
        height: "100%"
      },
      container: {
        backgroundColor: theme?.color.primaryBlack,
        height: "100%",
        marginTop: 23,
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
        padding: 32
      },
      closeButton: {
        width: 56,
        height: 56,
        borderRadius: 16,
        paddingHorizontal: 0,
        paddingVertical: 0,
        alignItems: "center",
        justifyContent: "center",
        color: theme?.color.secondaryWhite
      },
      store: {
        marginTop: 16,
        marginBottom: 48,
        fontSize: 26,
        lineHeight: 34,
        color: theme?.color.secondaryWhite
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.Modal, { animationType: "slide", ...props, children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.TouchableOpacity, { style: styles3.outside, onPress: close, children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.TouchableWithoutFeedback, { children: /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(import_react_native14.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
      ButtonWithChildren,
      {
        buttonColor: theme?.color.secondaryWhite + "1A",
        customStyle: styles3.closeButton,
        onPress: close,
        children: import_react_native14.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_io5.IoCloseOutline, { color: theme?.color.secondaryWhite, size: 28 }) : "x"
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.Text, { style: styles3.store, children: "Store_O Menu" }),
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(AppCards, { appCards })
  ] }) }) }) });
};

// src/components/common/AuthorizedByNFTHeader.tsx
var import_react11 = require("react");
var import_io52 = require("react-icons/io5");
var import_react_native15 = require("react-native");
var import_jsx_runtime14 = require("react/jsx-runtime");
var AuthorizedByNFTHeader = ({ headerCenterChildren, headerCenterCustomStyle, noSideButtons }) => {
  const theme = useTheme();
  const [isShowStoreMenu, setIsShowStoreMenu] = (0, import_react11.useState)(false);
  const styles3 = (0, import_react11.useMemo)(() => {
    return import_react_native15.StyleSheet.create({
      container: {
        minWidth: "100%",
        justifyContent: theme?.media.isMobile ? "space-between" : "center",
        flexDirection: "row",
        alignItems: "center"
      },
      // width: 1/3
      itemContainerOnNonMobile: {
        flex: 1,
        alignItems: "center"
      },
      // visibility: hidden
      itemContainerOnNoSideButtons: {
        opacity: 0,
        height: 0
      },
      fourDotsButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.dark,
        color: theme?.color.secondaryWhite,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      fourDotsButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16
      },
      fourDotsButtonNotMobile: {
        width: 56,
        height: 56,
        borderRadius: 28
      },
      fourDotsImage: {
        width: 20,
        height: 20
      },
      walletButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.mint,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      walletButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      walletButtonNotMobile: {
        borderRadius: 60,
        width: 147,
        height: 56
      },
      walletImageContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      },
      walletImage: {
        width: 29,
        height: 30
      },
      walletButtonText: {
        marginLeft: 8,
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.primaryBlack,
        fontWeight: "bold"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native15.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      import_react_native15.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.fourDotsButton,
              theme?.media.isMobile ? styles3.fourDotsButtonMobile : styles3.fourDotsButtonNotMobile
            ],
            onPress: () => setIsShowStoreMenu((prev) => !prev),
            children: isShowStoreMenu ? import_react_native15.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_io52.IoCloseOutline, { color: theme?.color.secondaryWhite, size: 28 }) : "x" : /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(Image, { source: { uri: "/icon/four_dots.svg" }, style: styles3.fourDotsImage })
          }
        )
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native15.View, { style: headerCenterCustomStyle, children: headerCenterChildren }),
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      import_react_native15.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.walletButton,
              theme?.media.isMobile ? styles3.walletButtonMobile : styles3.walletButtonNotMobile
            ],
            children: /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native15.View, { style: styles3.walletImageContainer, children: [
              /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(Image, { source: { uri: "/icon/wallet.svg" }, style: styles3.walletImage }),
              !theme?.media.isMobile && /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(Text3, { style: styles3.walletButtonText, children: "Wallet" })
            ] })
          }
        )
      }
    ),
    theme?.media.isMobile ? /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      AppsSlide,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: StoreMenuAppData.data
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      AppsModal,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: StoreMenuAppData.data
      }
    )
  ] });
};

// src/components/common/AuthorizationSubHeaderForProposal.tsx
var import_react12 = require("react");
var import_io53 = require("react-icons/io5");
var import_react_native16 = require("react-native");
var import_jsx_runtime15 = require("react/jsx-runtime");
var AuthorizationSubHeaderForProposal = ({
  headerCenterChildren,
  headerCenterCustomStyle,
  noSideButtons
}) => {
  const theme = useTheme();
  const [isShowStoreMenu, setIsShowStoreMenu] = (0, import_react12.useState)(false);
  const styles3 = (0, import_react12.useMemo)(() => {
    return import_react_native16.StyleSheet.create({
      container: {
        minWidth: "100%",
        justifyContent: theme?.media.isMobile ? "space-between" : "center",
        flexDirection: "row",
        alignItems: "center"
      },
      // width: 1/3
      itemContainerOnNonMobile: {
        flex: 1,
        alignItems: "center"
      },
      // visibility: hidden
      itemContainerOnNoSideButtons: {
        opacity: 0,
        height: 0
      },
      fourDotsButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.dark,
        color: theme?.color.secondaryWhite,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      fourDotsButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16
      },
      fourDotsButtonNotMobile: {
        width: 56,
        height: 56,
        borderRadius: 28
      },
      plusIconButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.dark,
        color: theme?.color.secondaryWhite,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      plusIconButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16
      },
      plusIconButtonNotMobile: {
        width: 56,
        height: 56,
        borderRadius: 28
      },
      homeIconImage: {
        width: 20,
        height: 20
      },
      plusIconImage: {
        width: 40,
        height: 40
      },
      walletButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.mint,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      walletButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      walletButtonNotMobile: {
        borderRadius: 60,
        width: 147,
        height: 56
      },
      walletImageContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      },
      walletImage: {
        width: 29,
        height: 30
      },
      walletButtonText: {
        marginLeft: 8,
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.primaryBlack,
        fontWeight: "bold"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(import_react_native16.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      import_react_native16.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.fourDotsButton,
              theme?.media.isMobile ? styles3.fourDotsButtonMobile : styles3.fourDotsButtonNotMobile
            ],
            onPress: () => {
              if (typeof window !== "undefined") {
                window.location.href = "/";
              }
            },
            children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Image, { source: { uri: "/icon/fi_home.svg" }, style: styles3.homeIconImage })
          }
        )
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_react_native16.View, { style: headerCenterCustomStyle, children: headerCenterChildren }),
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      import_react_native16.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.plusIconButton,
              theme?.media.isMobile ? styles3.plusIconButtonMobile : styles3.fourDotsButtonNotMobile
            ],
            children: isShowStoreMenu ? import_react_native16.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_io53.IoCloseOutline, { color: theme?.color.secondaryWhite, size: 28 }) : "x" : /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Image, { source: { uri: "/icon/plus.svg" }, style: styles3.plusIconImage })
          }
        )
      }
    ),
    theme?.media.isMobile ? /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      AppsSlide,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: ProposalMenuAppData.data
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      AppsModal,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: ProposalMenuAppData.data
      }
    )
  ] });
};

// src/components/common/ConnectWalletModal.tsx
var import_react13 = require("react");
var import_bs = require("react-icons/bs");
var import_react_native17 = require("react-native");
var import_jsx_runtime16 = require("react/jsx-runtime");
var ModalHeader = () => {
  const theme = useTheme();
  const styles3 = (0, import_react13.useMemo)(() => {
    return import_react_native17.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["2xl"] : theme?.fontSize["4xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsxs)(import_jsx_runtime16.Fragment, { children: [
    import_react_native17.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_react_native17.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_bs.BsExclamationCircleFill, { color: theme?.color.mint, size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(Text3, { style: styles3.title, children: "CONNECT" })
  ] });
};
var ConnectWalletModal = ({ ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(Modal, { headerChildren: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(ModalHeader, {}), ...props });
};

// src/components/common/ContainerLayout.tsx
var import_react_native18 = require("react-native");
var import_jsx_runtime17 = require("react/jsx-runtime");
var ContainerLayout = ({ children }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(import_react_native18.View, { style: styles2.container, children });
};
var styles2 = import_react_native18.StyleSheet.create({
  container: {
    width: "100%",
    minWidth: "100vw",
    position: "relative"
  }
});

// src/components/common/ExploreDeStoreModal.tsx
var import_react14 = require("react");
var import_bs2 = require("react-icons/bs");
var import_react_native19 = require("react-native");
var import_jsx_runtime18 = require("react/jsx-runtime");
var ModalHeader2 = () => {
  const theme = useTheme();
  const styles3 = (0, import_react14.useMemo)(() => {
    return import_react_native19.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["2xl"] : theme?.fontSize["4xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_jsx_runtime18.Fragment, { children: [
    import_react_native19.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_react_native19.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_bs2.BsExclamationCircleFill, { color: "#BDFDC7", size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(Text3, { style: styles3.title, children: [
      "NO NFT IN YOUR",
      /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("br", {}),
      "WALLET"
    ] })
  ] });
};
var ModalBody2 = ({ onNavigation }) => {
  const theme = useTheme();
  const styles3 = (0, import_react14.useMemo)(() => {
    return import_react_native19.StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 32
      },
      exploreButton: {
        width: "100%",
        maxWidth: 310,
        marginHorizontal: "auto",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme?.radius.full
      },
      buttonText: {
        fontSize: theme?.fontSize.md
      }
    });
  }, [theme?.media.isMobile]);
  const webStyle = {
    width: "100%",
    maxWidth: 310,
    margin: "0 auto",
    padding: theme?.media.isMobile ? "16px 20px" : "16px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme?.radius.full,
    background: theme?.color.mint,
    color: theme?.color.primaryBlack,
    textDecoration: "none"
  };
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_react_native19.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(Link, { href: "/stores", customStyle: styles3.exploreButton, webStyle, onPress: onNavigation, children: "Explore other destores" }) });
};
var ExploreDeStoreModal = ({ onNavigation, ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(Modal, { headerChildren: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(ModalHeader2, {}), bodyChildren: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(ModalBody2, { onNavigation }), ...props });
};

// src/components/common/Header.tsx
var import_react15 = require("react");
var import_react_native20 = require("react-native");
var import_jsx_runtime19 = require("react/jsx-runtime");
var Header = ({
  imagePath,
  isLoading,
  addressOrEnsName,
  isWagmiConnected,
  handleConnect,
  handleDisConnect
}) => {
  const theme = useTheme();
  const isConnectedPaperState = useAppSelector((state) => state.auth.connectedPaperWallet);
  const shortenAddress = (address) => {
    const prefixLength = 6;
    const suffixLength = 4;
    const prefix2 = address.slice(0, prefixLength);
    const suffix = address.slice(-suffixLength);
    return `${prefix2}...${suffix}`;
  };
  const subText = addressOrEnsName ? shortenAddress(addressOrEnsName) : void 0;
  const renderButton = (0, import_react15.useCallback)(() => {
    const isConnected = isWagmiConnected || isConnectedPaperState;
    const title = isConnected ? "DisConnect Wallet" : "Connect Wallet";
    const onPress = isConnected ? handleDisConnect : handleConnect;
    if (isLoading) {
      return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(OutlineButton, { title: "Loading" });
    }
    return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(OutlineButton, { title, onPress, subText });
  }, [isWagmiConnected, isConnectedPaperState, isLoading]);
  const styles3 = (0, import_react15.useMemo)(() => {
    return import_react_native20.StyleSheet.create({
      container: {
        width: "100%",
        maxWidth: 1420,
        padding: 20,
        display: theme?.media.isMobile ? "none" : "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottomWidth: 1,
        borderBottomColor: "rgba(255, 255, 255, 0.1)",
        position: "relative",
        zIndex: 2
      },
      image: {
        width: 156,
        height: 30
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(import_react_native20.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(Link, { href: "/", children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(Image, { style: styles3.image, source: { uri: imagePath } }) }),
    renderButton()
  ] });
};

// src/components/common/MobileHeader.tsx
var import_react16 = require("react");
var import_io54 = require("react-icons/io5");
var import_react_native21 = require("react-native");
var import_jsx_runtime20 = require("react/jsx-runtime");
var MobileHeader = ({
  logoPath,
  isLoading,
  currentPath,
  isWagmiConnected,
  handleBackHome,
  handleBackNavigate,
  handleConnect,
  handleDisConnect
}) => {
  const theme = useTheme();
  const isConnectedPaper = useAppSelector((state) => state.auth.connectedPaperWallet);
  const styles3 = (0, import_react16.useMemo)(() => {
    return import_react_native21.StyleSheet.create({
      container: {
        width: "100%",
        display: theme?.media.isMobile ? "flex" : "none",
        position: currentPath.split("/")[2] === "nfts" ? "absolute" : void 0,
        top: 44,
        left: 0,
        zIndex: 3
      },
      nav: {
        width: "100%",
        paddingHorizontal: 32,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      logoContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      iconContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        backgroundColor: "transparent"
      },
      connectButton: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        left: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.secondaryWhite,
        color: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  const renderButton = (0, import_react16.useCallback)(() => {
    const isConnected = isWagmiConnected || isConnectedPaper;
    const title = isConnected ? "DisConnect Wallet" : "Connect Wallet";
    const onPress = isWagmiConnected || isConnectedPaper ? handleDisConnect : handleConnect;
    if (isLoading)
      return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Button, { customStyle: styles3.connectButton, title: "Loading" });
    return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Button, { customStyle: styles3.connectButton, title, onPress });
  }, [isWagmiConnected, isConnectedPaper, isLoading]);
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_react_native21.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(import_react_native21.View, { style: styles3.nav, children: [
    currentPath === "/stores" ? /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(ButtonWithChildren, { customStyle: styles3.logoContainer, onPress: handleBackHome, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Image, { source: { uri: logoPath, width: 36, height: 36 } }) }) : /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(ButtonWithChildren, { customStyle: styles3.iconContainer, onPress: handleBackNavigate, children: import_react_native21.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_io54.IoChevronBack, { size: 16, color: "#ffffff" }) : null }),
    renderButton()
  ] }) });
};

// src/components/common/TabButtonGroupSpacer.tsx
var import_react17 = require("react");
var import_react_native22 = require("react-native");
var import_jsx_runtime21 = require("react/jsx-runtime");
var TabGroupSpacer = () => {
  const theme = useTheme();
  const styles3 = (0, import_react17.useMemo)(() => {
    return import_react_native22.StyleSheet.create({
      spacer: {
        width: "100%",
        height: theme?.media.isMobile ? (
          // 64px (live-tab-group.tsx) + 32px (overlay-tabs.tsx)
          96
        ) : (
          // 56px (live-tab-group.tsx) + 48px (overlay-tabs.tsx)
          104
        )
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_react_native22.View, { style: styles3.spacer });
};

// src/components/common/TabOverlay.tsx
var import_react20 = require("react");
var import_react_native25 = require("react-native");

// src/components/common/TabButtonGroup.tsx
var import_react19 = require("react");
var import_react_native24 = require("react-native");

// src/components/common/TabButtonWithLabel.tsx
var import_react18 = require("react");
var import_react_native23 = require("react-native");
var import_jsx_runtime22 = require("react/jsx-runtime");
var TabButtonWithLabel = ({
  active,
  activeImagePath,
  inactiveImagePath,
  name,
  marginHorizontal,
  onPress
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react18.useMemo)(() => {
    return import_react_native23.StyleSheet.create({
      image: {
        width: 32,
        height: 32,
        marginRight: theme?.media.isMobile ? 0 : 8
      },
      text: {
        color: active ? theme?.color.secondaryWhite : theme?.color.secondaryGray,
        fontSize: 14,
        lineHeight: 18
      },
      button: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: theme?.color.primaryBlack,
        flexGrow: 0,
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginHorizontal: marginHorizontal ?? 0,
        borderRadius: theme?.radius.none
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(ButtonWithChildren, { customStyle: styles3.button, disabled: active, onPress, children: [
    /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(Image, { source: { uri: active ? activeImagePath : inactiveImagePath }, style: styles3.image }),
    !theme?.media.isMobile && /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(Text3, { style: styles3.text, children: name })
  ] });
};

// src/components/common/TabButtonGroup.tsx
var import_jsx_runtime23 = require("react/jsx-runtime");
var TabButtonGroup = ({
  isActiveDashboard,
  isActiveLive,
  isActiveSettings,
  navigateToDashboard,
  navigateToLive,
  navigateToSettings
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react19.useMemo)(() => {
    return import_react_native24.StyleSheet.create({
      container: {
        borderRadius: theme?.media.isMobile ? 30 : 70,
        backgroundColor: theme?.color.dark,
        width: theme?.media.isMobile ? 256 : 439,
        height: theme?.media.isMobile ? 64 : 56,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsxs)(import_react_native24.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      TabButtonWithLabel,
      {
        active: isActiveDashboard,
        activeImagePath: "/icon/dashboard_active.svg",
        inactiveImagePath: "/icon/dashboard_inactive.svg",
        name: "Dashboard",
        onPress: navigateToDashboard
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      TabButtonWithLabel,
      {
        active: isActiveLive,
        activeImagePath: "/icon/live_active.svg",
        inactiveImagePath: "/icon/live_inactive.svg",
        name: "Live",
        marginHorizontal: 40,
        onPress: navigateToLive
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      TabButtonWithLabel,
      {
        active: isActiveSettings,
        activeImagePath: "/icon/settings_active.svg",
        inactiveImagePath: "/icon/settings_inactive.svg",
        name: "Settings",
        onPress: navigateToSettings
      }
    )
  ] });
};

// src/components/common/TabOverlay.tsx
var import_jsx_runtime24 = require("react/jsx-runtime");
var TabOverlay = ({ children, tabButtonGroupProps }) => {
  const theme = useTheme();
  const containerStylePosition = children ? {
    top: 0,
    height: "100%"
  } : {
    bottom: 0
  };
  const styles3 = (0, import_react20.useMemo)(() => {
    return import_react_native25.StyleSheet.create({
      container: {
        position: "absolute",
        left: 0,
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 1,
        ...containerStylePosition
      },
      top: {
        flexGrow: children ? 1 : 0
      },
      bottom: {
        flexGrow: 0,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme?.media.isMobile ? 32 : 48
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)(import_react_native25.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_react_native25.View, { style: styles3.top, children }),
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_react_native25.View, { style: styles3.bottom, children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(TabButtonGroup, { ...tabButtonGroupProps }) })
  ] });
};

// src/components/common/WalletConfirmModal.tsx
var import_react21 = require("react");
var import_bs3 = require("react-icons/bs");
var import_ri = require("react-icons/ri");
var import_react_native26 = require("react-native");
var import_jsx_runtime25 = require("react/jsx-runtime");
var ModalHeader3 = () => {
  const theme = useTheme();
  const styles3 = (0, import_react21.useMemo)(() => {
    return import_react_native26.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["2xl"] : theme?.fontSize["4xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
    import_react_native26.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_react_native26.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_bs3.BsExclamationCircleFill, { color: "#BDFDC7", size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Text3, { style: styles3.title, children: "HAVE YOUR WALLET ALREADY?" })
  ] });
};
var ModalBody3 = ({
  handleShowConnectModal,
  handleCreateWallet
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react21.useMemo)(() => {
    return import_react_native26.StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 32
      },
      connectButton: {
        width: "100%",
        maxWidth: 310,
        marginHorizontal: "auto",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme?.radius.full
      },
      connectButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10
      },
      buttonText: {
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      createButtonContainer: {
        marginTop: 40
      },
      createButtonText: {
        textAlign: "center"
      },
      createButton: {
        marginTop: 16,
        borderWidth: 2,
        color: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_react_native26.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.connectButton,
        buttonColor: theme?.color.mint,
        onPress: handleShowConnectModal,
        children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_react_native26.View, { style: styles3.connectButtonContainer, children: [
          import_react_native26.Platform.OS === "web" && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_ri.RiWalletLine, { size: 24 }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Text3, { style: styles3.buttonText, children: "Connect Wallet" })
        ] })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_react_native26.View, { style: styles3.createButtonContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Text3, { style: styles3.createButtonText, children: "No Wallet yet?" }),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        OutlineButton,
        {
          customStyle: styles3.createButton,
          title: "Create your wallet with email",
          onPress: handleCreateWallet
        }
      )
    ] })
  ] });
};
var WalletConfirmModal = ({ ...props }) => {
  const { handleShowConnectModal, handleCreateWallet } = props;
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    Modal,
    {
      headerChildren: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(ModalHeader3, {}),
      bodyChildren: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(ModalBody3, { handleShowConnectModal, handleCreateWallet }),
      ...props
    }
  );
};

// src/components/common/NetworkDifferentModal.tsx
var import_react22 = require("react");
var import_bi = require("react-icons/bi");
var import_bs4 = require("react-icons/bs");
var import_react_native27 = require("react-native");
var import_jsx_runtime26 = require("react/jsx-runtime");
var ModalHeader4 = () => {
  const theme = useTheme();
  const styles3 = (0, import_react22.useMemo)(() => {
    return import_react_native27.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["xl"] : theme?.fontSize["2xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(import_jsx_runtime26.Fragment, { children: [
    import_react_native27.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_react_native27.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_bs4.BsExclamationCircleFill, { color: "#BDFDC7", size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(Text3, { style: styles3.title, children: "You have to change correct network" })
  ] });
};
var ModalBody4 = ({
  handleConnectCorrectNetwork
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react22.useMemo)(() => {
    return import_react_native27.StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 32
      },
      connectButton: {
        width: "100%",
        maxWidth: 310,
        marginHorizontal: "auto",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme?.radius.full
      },
      connectButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10
      },
      buttonText: {
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      createButtonContainer: {
        marginTop: 40
      },
      createButtonText: {
        textAlign: "center"
      },
      createButton: {
        marginTop: 16,
        borderWidth: 2,
        color: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_react_native27.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    ButtonWithChildren,
    {
      customStyle: styles3.connectButton,
      buttonColor: theme?.color.mint,
      onPress: handleConnectCorrectNetwork,
      children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(import_react_native27.View, { style: styles3.connectButtonContainer, children: [
        import_react_native27.Platform.OS === "web" && /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_bi.BiSolidNetworkChart, { size: 24 }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(Text3, { style: styles3.buttonText, children: "Change Network" })
      ] })
    }
  ) });
};
var NetworkDifferentModal = ({ ...props }) => {
  const { handleConnectCorrectNetwork } = props;
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    Modal,
    {
      headerChildren: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(ModalHeader4, {}),
      bodyChildren: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(ModalBody4, { handleConnectCorrectNetwork }),
      ...props
    }
  );
};

// src/components/pages/dashboard/DashboardIndicators.tsx
var import_react23 = require("react");
var import_react_native28 = require("react-native");
var import_jsx_runtime27 = require("react/jsx-runtime");
var DashboardIndicators = ({
  treasuryBalance,
  storeBalance,
  expense,
  grossSales,
  members,
  profit
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react23.useMemo)(() => {
    return import_react_native28.StyleSheet.create({
      container: {
        marginTop: theme?.media.isMobile ? 24 : 32
      },
      indicator: {
        backgroundColor: theme?.color.secondaryLight,
        borderRadius: 10,
        flex: 1,
        height: 118,
        padding: 16
      },
      iconContainer: {
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24
      },
      icon: {
        width: 24,
        height: 24
      },
      title: {
        fontSize: 12,
        lineHeight: 16,
        marginTop: 8,
        marginBottom: 4
      },
      value: {
        fontSize: 26,
        lineHeight: 34
      },
      separator: {
        height: 8
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
    import_react_native28.FlatList,
    {
      style: styles3.container,
      numColumns: theme?.media.isMobile ? 2 : 3,
      data: [
        {
          iconPath: "/icon/wallet_outline.svg",
          title: "Treasury balance",
          value: treasuryBalance,
          iconStyle: {
            width: 19,
            height: 18
          }
        },
        {
          iconPath: "/icon/package_variant_closed.svg",
          title: "Store balance",
          value: storeBalance,
          iconStyle: {
            width: 19,
            height: 20
          }
        },
        {
          iconPath: "/icon/trending_down.svg",
          title: "Expense",
          value: expense,
          iconStyle: {
            width: 18,
            height: 12
          }
        },
        {
          iconPath: "/icon/cart_outline.svg",
          title: "Gross sales",
          value: grossSales,
          iconStyle: {
            width: 21,
            height: 20
          }
        },
        {
          iconPath: "/icon/user.svg",
          title: "Members",
          value: members,
          iconStyle: {
            width: 16,
            height: 16
          }
        },
        {
          iconPath: "/icon/fire.svg",
          title: "Profit",
          value: profit,
          iconStyle: {
            width: 15,
            height: 18
          }
        }
      ],
      renderItem: ({ item: { iconPath, title, value, iconStyle }, index }) => /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
        import_react_native28.View,
        {
          style: [
            styles3.indicator,
            theme?.media.isMobile ? index % 2 === 0 && { marginRight: 8 } : index % 3 !== 2 && { marginRight: 8 }
          ],
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native28.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(Image, { source: { uri: iconPath }, style: iconStyle }) }),
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(Text3, { style: styles3.title, children: title }),
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(Text3, { style: styles3.value, children: value })
          ]
        }
      ),
      ItemSeparatorComponent: () => /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native28.View, { style: styles3.separator })
    },
    theme?.media.isMobile ? 2 : 3
  );
};

// src/components/pages/dashboard/DashboardTabView.tsx
var import_react27 = require("react");
var import_react_native32 = require("react-native");
var import_react_native_tab_view = require("react-native-tab-view");

// src/components/pages/dashboard/DashboardTab.tsx
var import_randomcolor = __toESM(require("randomcolor"));
var import_react26 = require("react");
var import_react_native31 = require("react-native");

// src/components/common/Divider.tsx
var import_react24 = require("react");
var import_react_native29 = require("react-native");
var import_jsx_runtime28 = require("react/jsx-runtime");
var Divider = ({ color, customStyle }) => {
  const theme = useTheme();
  const styles3 = (0, import_react24.useMemo)(() => {
    return import_react_native29.StyleSheet.create({
      divider: {
        width: "100%",
        height: 1,
        backgroundColor: color,
        ...customStyle
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_react_native29.View, { style: styles3.divider });
};

// src/components/pages/dashboard/DashboardLegendTable.tsx
var import_react25 = require("react");
var import_react_native30 = require("react-native");
var import_jsx_runtime29 = require("react/jsx-runtime");
var DashboardLegendTable = ({ items: items2 }) => {
  const theme = useTheme();
  const styles3 = (0, import_react25.useMemo)(() => {
    return import_react_native30.StyleSheet.create({
      table: {
        flex: 1
      },
      row: {
        flexDirection: "row",
        alignItems: "center"
      },
      symbolColumn: {
        marginRight: 16
      },
      symbol: {
        width: 10,
        height: 6,
        borderRadius: 3
      },
      brand: {
        flex: 2
      },
      brandText: {
        fontSize: 16,
        lineHeight: 20,
        color: theme?.color.primaryBlack
      },
      value: {
        flex: 1,
        textAlign: "right"
      },
      valueText: {
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.primaryBlack
      },
      percentage: {
        textAlign: "right",
        width: 40,
        marginLeft: 16
      },
      percentageText: {
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.secondaryGray
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.table, children: items2?.map((row, i) => /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_react_native30.View, { style: [styles3.row, { marginBottom: items2.length - 1 === i ? 0 : 8 }], children: [
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.symbolColumn, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: [styles3.symbol, { backgroundColor: row.color }] }) }),
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.brand, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Text3, { style: styles3.brandText, children: "-" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.value, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Text3, { style: styles3.valueText, children: "$-" }) }),
    typeof row.percentage === "number" && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.percentage, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Text3, { style: styles3.percentageText, children: "-%" }) })
  ] }, row.name)) });
};

// src/components/pages/dashboard/DashboardTab.tsx
var import_jsx_runtime30 = require("react/jsx-runtime");
var DashboardTab = ({
  route: {
    brandSalesRankingData,
    salesAndExpenseList,
    dashboardIndicators,
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryPie,
    VictoryStack
  }
}) => {
  const dataTotal = brandSalesRankingData?.reduce((sum, bsr) => sum + bsr.sales, 0) ?? 0;
  const brandSalesRankingColorScale = (0, import_randomcolor.default)({ count: brandSalesRankingData.length, luminosity: "light" });
  const salesTotal = salesAndExpenseList?.reduce((sum, se) => sum + se.sales, 0) ?? 0;
  const expenseTotal = salesAndExpenseList?.reduce((sum, se) => sum + se.expense, 0) ?? 0;
  const salesAndExpenseColorScale = (0, import_randomcolor.default)({ count: 2, luminosity: "light" });
  const theme = useTheme();
  const getAbbreviatedNumber = (0, import_react26.useCallback)(
    (num) => Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1
    }).format(num),
    []
  );
  const getPlusMinus = (0, import_react26.useCallback)((salesTotal2, expenseTotal2) => {
    const result = salesTotal2 - expenseTotal2;
    if (result === 0) {
      return "";
    } else if (result > 0) {
      return "+";
    } else {
      return "-";
    }
  }, []);
  const styles3 = (0, import_react26.useMemo)(() => {
    return import_react_native31.StyleSheet.create({
      brandSalesRankingContainer: {
        width: "100%",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 8,
        paddingVertical: 16,
        marginTop: theme?.media.isMobile ? 16 : 32
      },
      brandSalesRankingSummaryContainer: {
        width: theme?.media.isMobile ? "100%" : "50%",
        marginBottom: theme?.media.isMobile ? 24 : 0,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 24
      },
      brandSalesRankingSummaryPieContainer: {
        width: 109,
        height: 109
      },
      brandSalesRankingSummaryTotalContainer: {
        flexDirection: "column",
        gap: 4
      },
      brandSalesRankingSummaryTotalTitle: {
        fontSize: 12,
        lineHeight: 16,
        color: theme?.color.primaryBlack
      },
      brandSalesRankingSummaryTotal: {
        fontSize: 26,
        lineHeight: 34,
        color: theme?.color.primaryBlack
      },
      brandSalesRankingLegendContainer: {
        width: theme?.media.isMobile ? "100%" : "50%"
      },
      centerDivider: {
        marginVertical: theme?.media.isMobile ? 16 : 32
      },
      salesAndExpenseContainer: {
        width: "100%",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center"
      },
      salesAndExpenseGraphContainer: {
        width: theme?.media.isMobile ? "100%" : "50%"
      },
      salesAndExpenseLegendContainer: {
        width: theme?.media.isMobile ? "100%" : "50%"
      },
      salesAndExpenseTitle: {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 18,
        textAlign: "center",
        marginTop: theme?.media.isMobile ? 16 : 18
      },
      salesAndExpenseDivider: {
        marginVertical: 16
      },
      salesAndExpenseTotalContainer: {
        flexDirection: "row",
        justifyContent: "space-between"
      },
      salesAndExpenseTotalTitle: {
        fontSize: 16,
        lineHeight: 20
      },
      salesAndExpenseTotalValue: {
        fontSize: 26,
        lineHeight: 34
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_jsx_runtime30.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(DashboardIndicators, { ...dashboardIndicators }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.brandSalesRankingContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.brandSalesRankingSummaryContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_react_native31.View, { style: styles3.brandSalesRankingSummaryPieContainer, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
          VictoryPie,
          {
            colorScale: brandSalesRankingColorScale,
            data: brandSalesRankingData?.map((bsr) => ({ x: bsr.brandName, y: bsr.sales })),
            padAngle: 1,
            cornerRadius: 15,
            padding: 0,
            innerRadius: 130,
            style: { labels: { display: "none" } }
          }
        ) }),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.brandSalesRankingSummaryTotalContainer, children: [
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.brandSalesRankingSummaryTotalTitle, children: "Brand Sales Ranking" }),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.brandSalesRankingSummaryTotal, children: "$-" })
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_react_native31.View, { style: styles3.brandSalesRankingLegendContainer, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
        DashboardLegendTable,
        {
          items: brandSalesRankingData.map((bsr, i) => ({
            color: brandSalesRankingColorScale[i],
            name: bsr.brandName,
            value: bsr.sales,
            percentage: Math.round(bsr.sales / dataTotal * 100)
          }))
        }
      ) })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Divider, { color: theme?.color.secondaryLightGreen, customStyle: styles3.centerDivider }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseGraphContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.salesAndExpenseTitle, children: "Sales and expense" }),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(VictoryChart, { domainPadding: { x: [20, 0], y: [0, 0] }, children: [
          /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(VictoryStack, { colorScale: salesAndExpenseColorScale, children: [
            /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(VictoryBar, { data: salesAndExpenseList?.map((se) => ({ x: se.month, y: se.expense })) }),
            /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(VictoryBar, { data: salesAndExpenseList?.map((se) => ({ x: se.month, y: se.sales })) })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            VictoryAxis,
            {
              tickFormat: salesAndExpenseList?.map((se) => se.month),
              style: {
                axis: {
                  stroke: "transparent"
                }
              }
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            VictoryAxis,
            {
              dependentAxis: true,
              style: {
                axis: {
                  stroke: "transparent"
                }
              },
              tickFormat: (t) => `$${getAbbreviatedNumber(t)}`
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseLegendContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
          DashboardLegendTable,
          {
            items: [
              {
                color: salesAndExpenseColorScale[1],
                name: "Sales",
                value: salesTotal
              },
              {
                color: salesAndExpenseColorScale[0],
                name: "Expense",
                value: expenseTotal
              }
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Divider, { color: theme?.color.secondaryLight, customStyle: styles3.salesAndExpenseDivider }),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseTotalContainer, children: [
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.salesAndExpenseTotalTitle, children: "Total:" }),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.salesAndExpenseTotalValue, children: "+ $-" })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(TabGroupSpacer, {})
  ] });
};

// src/components/pages/dashboard/DashboardTabView.tsx
var import_jsx_runtime31 = require("react/jsx-runtime");
var DashboardTabview = (props) => {
  const theme = useTheme();
  const [navigationState, setNavigationState] = (0, import_react27.useState)({
    index: 0,
    routes: [
      { key: "daily", title: "Daily", ...props },
      { key: "weekly", title: "Weekly", ...props },
      { key: "monthly", title: "Monthly", ...props },
      { key: "yearly", title: "Yearly", ...props }
    ]
  });
  const styles3 = (0, import_react27.useMemo)(() => {
    return import_react_native32.StyleSheet.create({
      tabTitle: {
        fontSize: 14,
        lineHeight: 18
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
    import_react_native_tab_view.TabView,
    {
      initialLayout: { width: import_react_native32.Dimensions.get("window").width },
      navigationState,
      renderScene: (0, import_react_native_tab_view.SceneMap)({
        daily: DashboardTab,
        weekly: DashboardTab,
        monthly: DashboardTab,
        yearly: DashboardTab
      }),
      renderTabBar: (props2) => /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
        import_react_native_tab_view.TabBar,
        {
          ...props2,
          indicatorStyle: { backgroundColor: theme?.color.primaryBlack },
          style: { backgroundColor: theme?.color.secondaryWhite },
          activeColor: theme?.color.primaryBlack,
          inactiveColor: theme?.color.secondaryGray,
          renderLabel: ({ color, route }) => /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(Text3, { style: [styles3.tabTitle, { color }], children: route.title })
        }
      ),
      onIndexChange: (index) => setNavigationState((prev) => ({ ...prev, index }))
    }
  );
};

// src/components/pages/home/HomeCta.tsx
var import_react28 = require("react");
var import_react_native33 = require("react-native");
var import_jsx_runtime32 = require("react/jsx-runtime");
var HomeCta = ({ logoImagePath, keyImagePath }) => {
  const theme = useTheme();
  const styles3 = (0, import_react28.useMemo)(() => {
    return import_react_native33.StyleSheet.create({
      container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: theme?.media.isMobile ? "flex-end" : "center",
        justifyContent: "space-between"
      },
      baseText: {
        display: theme?.media.isMobile ? "none" : "flex",
        flexDirection: "column",
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["6xl"],
        fontWeight: "bold",
        lineHeight: 60
      },
      mobileBaseText: {
        display: theme?.media.isMobile ? "flex" : "none",
        flexDirection: "column",
        marginTop: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["6xl"],
        fontWeight: "bold"
      },
      textInner: {
        display: "flex"
      },
      textStrong: {
        marginLeft: 16,
        color: theme?.color.mint
      },
      logoImage: {
        width: 64,
        height: 64
      },
      keyImage: {
        width: 200,
        height: 200
      },
      introduction: {
        marginTop: theme?.media.isMobile ? 16 : 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(import_react_native33.View, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(import_react_native33.View, { style: styles3.container, children: [
      /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(import_react_native33.View, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Image, { style: styles3.logoImage, source: { uri: logoImagePath } }),
        /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.baseText, children: [
          "NEW RETAIL",
          /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.textInner, children: [
            "IS",
            /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Text3, { style: styles3.textStrong, children: "HERE." })
          ] })
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Image, { style: styles3.keyImage, source: { uri: keyImagePath } })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.mobileBaseText, children: [
      "NEW RETAIL",
      /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.textInner, children: [
        "IS",
        /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Text3, { style: styles3.textStrong, children: "HERE." })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Text3, { style: styles3.introduction, children: "The platform that you become part of your favorite store." })
  ] });
};

// src/components/pages/home/HomeEnterDeStoreSection.tsx
var import_react29 = require("react");
var import_fi2 = require("react-icons/fi");
var import_react_native34 = require("react-native");
var import_jsx_runtime33 = require("react/jsx-runtime");
var HomeEnterDeStoreSection = ({ handleOpenClick }) => {
  const theme = useTheme();
  const styles3 = (0, import_react29.useMemo)(() => {
    return import_react_native34.StyleSheet.create({
      container: {
        width: "full",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        justifyContent: "flex-start",
        borderRadius: 16
      },
      inner: {
        width: "full",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)",
        textAlign: "left"
      },
      title: {
        fontSize: theme?.media.isMobile ? theme.fontSize.xl : theme?.fontSize["2xl"],
        fontWeight: "bold"
      },
      subText: {
        marginTop: 8,
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.md
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(ButtonWithChildren, { customStyle: styles3.container, buttonColor: theme?.color.mint, onPress: handleOpenClick, children: /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_react_native34.View, { style: styles3.inner, children: [
    /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_react_native34.View, { style: styles3.textContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(Text3, { style: styles3.title, children: "ENTER YOUR COMMUNITY" }),
      /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(Text3, { style: styles3.subText, children: "Jump in the store if you have already got a membership" })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_react_native34.View, { style: styles3.iconContainer, children: import_react_native34.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_fi2.FiArrowUpRight, { color: "#BDFDC7", size: 22 }) : null })
  ] }) });
};

// src/components/pages/home/HomeExploreDeStoreSection.tsx
var import_react30 = require("react");
var import_fi3 = require("react-icons/fi");
var import_react_native35 = require("react-native");
var import_jsx_runtime34 = require("react/jsx-runtime");
var HomeExploreDeStoreSection = ({ onNavigation }) => {
  const theme = useTheme();
  const styles3 = (0, import_react30.useMemo)(() => {
    return import_react_native35.StyleSheet.create({
      container: {
        width: "full",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        display: "flex",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme?.color.mint,
        borderRadius: 16
      },
      inner: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)",
        textAlign: "left"
      },
      title: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.media.isMobile ? theme.fontSize.xl : theme?.fontSize["2xl"],
        fontWeight: "bold"
      },
      subText: {
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.md
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      }
    });
  }, [theme?.media.isMobile]);
  const webStyle = {
    width: "full",
    padding: theme?.media.isMobile ? "20px 20px" : "32px 40px",
    display: "flex",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme?.color.mint,
    borderRadius: 16,
    textDecoration: "none"
  };
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(Link, { href: "/stores", customStyle: styles3.container, webStyle, onPress: onNavigation, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_react_native35.View, { style: styles3.inner, children: [
    /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_react_native35.View, { style: styles3.textContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(Text3, { style: styles3.title, children: "EXPLORE OTHER COMMUNITIES" }),
      /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(Text3, { style: styles3.subText, children: "Check in all communities that are available on the network" })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_react_native35.View, { style: styles3.iconContainer, children: import_react_native35.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_fi3.FiArrowUpRight, { color: "#BDFDC7", size: 22 }) : null })
  ] }) });
};

// src/components/pages/live/LiveHeaderCenterChildren.tsx
var import_react31 = require("react");
var import_react_native36 = require("react-native");
var import_jsx_runtime35 = require("react/jsx-runtime");
var LiveHeaderCenterChildren = ({ logoImagePath, address, addressColor }) => {
  const theme = useTheme();
  const styles3 = (0, import_react31.useMemo)(() => {
    return import_react_native36.StyleSheet.create({
      storeLogo: {
        height: 30,
        width: "100%",
        marginBottom: theme?.media.isMobile ? 8 : 15
      },
      storeAddress: {
        fontSize: theme?.media.isMobile ? 12 : 14,
        lineHeight: theme?.media.isMobile ? 14 : 18,
        color: addressColor ?? theme?.color.secondaryWhite
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(import_jsx_runtime35.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
      Image,
      {
        source: {
          uri: logoImagePath
        },
        style: styles3.storeLogo,
        resizeMode: "contain"
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(Text3, { style: styles3.storeAddress, children: address })
  ] });
};

// src/components/pages/live/LiveLineItems.tsx
var import_react33 = require("react");
var import_react_native38 = require("react-native");

// src/components/pages/live/LiveLineItem.tsx
var import_react32 = require("react");
var import_react_native37 = require("react-native");
var import_jsx_runtime36 = require("react/jsx-runtime");
var LiveLineItem = ({ lineItem, isNextElementToBeHidden }) => {
  const theme = useTheme();
  const styles3 = (0, import_react32.useMemo)(() => {
    return import_react_native37.StyleSheet.create({
      container: {
        width: theme?.media.isMobile ? LineItemCardSizes.mobile.width : LineItemCardSizes.desktop.width,
        height: theme?.media.isMobile ? LineItemCardSizes.mobile.height : LineItemCardSizes.desktop.height,
        justifyContent: "space-between",
        // secondaryWhite alpha 20%
        backgroundColor: theme?.color.black + "33",
        borderColor: theme?.color.secondaryGray,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        padding: 8,
        opacity: isNextElementToBeHidden ? 0.4 : 1
      },
      product: {
        fontSize: theme?.media.isMobile ? 14 : 18,
        lineHeight: theme?.media.isMobile ? 18 : 24,
        fontWeight: "bold",
        color: theme?.color.secondaryWhite,
        marginBottom: 2
      },
      brand: {
        fontSize: theme?.media.isMobile ? 12 : 14,
        lineHeight: theme?.media.isMobile ? 16 : 18,
        color: theme?.color.secondaryWhite
      },
      amountContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row"
      },
      amount: {
        fontSize: theme?.media.isMobile ? 12 : 18,
        lineHeight: theme?.media.isMobile ? 16 : 24,
        color: theme?.color.mint
      },
      dateTime: {
        fontSize: theme?.media.isMobile ? 12 : 14,
        lineHeight: theme?.media.isMobile ? 16 : 18,
        color: theme?.color.secondaryWhite,
        opacity: 0.5
      }
    });
  }, [theme?.media.isMobile, isNextElementToBeHidden]);
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(import_react_native37.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(import_react_native37.View, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(Text3, { style: styles3.product, numberOfLines: 1, children: [
        lineItem.name,
        lineItem.quantity > 1 && ` (x${lineItem.quantity})`
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(Text3, { style: styles3.brand, numberOfLines: 1, children: lineItem.vendor })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(import_react_native37.View, { style: styles3.amountContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(Text3, { style: styles3.amount, numberOfLines: 1, children: [
        "+$",
        lineItem.price.toFixed(2)
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(Text3, { style: styles3.dateTime, numberOfLines: 1, children: lineItem.updatedAt })
    ] })
  ] });
};

// src/components/pages/live/LiveLineItems.tsx
var import_jsx_runtime37 = require("react/jsx-runtime");
var LiveLineItems = ({ lineItems }) => {
  const theme = useTheme();
  const scrollViewRef = (0, import_react33.useRef)(null);
  const isCloseToBottomRef = (0, import_react33.useRef)(true);
  const numberOfDisplay = theme?.media.isMobile ? 3 : 4;
  const spaceBetweenItems = theme?.media.isMobile ? 8 : 16;
  const lineItemWidth = theme?.media.isMobile ? LineItemCardSizes.mobile.width : LineItemCardSizes.desktop.width;
  const lineItemHeight = theme?.media.isMobile ? LineItemCardSizes.mobile.height : LineItemCardSizes.desktop.height;
  const itemsViewWidth = lineItemWidth;
  const itemsViewHeight = lineItemHeight * numberOfDisplay + spaceBetweenItems * (numberOfDisplay > 1 ? numberOfDisplay - 1 : 0);
  const styles3 = (0, import_react33.useMemo)(() => {
    return import_react_native38.StyleSheet.create({
      scrollContainer: {
        width: itemsViewWidth,
        height: itemsViewHeight
      },
      scrollView: {
        flexDirection: "column",
        gap: spaceBetweenItems
      }
    });
  }, [theme?.media.isMobile]);
  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height;
  };
  return /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(import_react_native38.View, { style: styles3.scrollContainer, children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
    import_react_native38.ScrollView,
    {
      showsVerticalScrollIndicator: false,
      ref: scrollViewRef,
      onContentSizeChange: () => {
        if (isCloseToBottomRef.current && scrollViewRef.current) {
          scrollViewRef.current.scrollToEnd();
        }
      },
      onScroll: ({ nativeEvent }) => {
        if (!isCloseToBottomRef)
          return;
        isCloseToBottomRef.current = isCloseToBottom(nativeEvent);
      },
      scrollEventThrottle: 1,
      children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(import_react_native38.View, { style: styles3.scrollView, children: lineItems?.map((li, i) => /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
        LiveLineItem,
        {
          lineItem: li,
          isNextElementToBeHidden: lineItems.length - numberOfDisplay >= i
        },
        `line-item-${i}`
      )) })
    }
  ) });
};

// src/components/pages/live/LiveSalesInfoItem.tsx
var import_react34 = require("react");
var import_react_native39 = require("react-native");
var import_jsx_runtime38 = require("react/jsx-runtime");
var LiveSalesInfoItem = ({ title, value }) => {
  const theme = useTheme();
  const styles3 = (0, import_react34.useMemo)(() => {
    return import_react_native39.StyleSheet.create({
      title: {
        fontSize: theme?.media.isMobile ? 14 : 16,
        lineHeight: theme?.media.isMobile ? 18 : 20,
        color: theme?.color.secondaryWhite,
        marginBottom: theme?.media.isMobile ? 0 : 8
      },
      value: {
        fontSize: theme?.media.isMobile ? 22 : 26,
        lineHeight: theme?.media.isMobile ? 28 : 34,
        color: theme?.color.mint
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime38.jsxs)(import_react_native39.View, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime38.jsxs)(Text3, { style: styles3.title, numberOfLines: 1, children: [
      title,
      ":"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(Text3, { style: styles3.value, numberOfLines: 1, children: value })
  ] });
};

// src/components/pages/live/LiveSalesInfoItems.tsx
var import_react35 = require("react");
var import_react_native40 = require("react-native");
var import_jsx_runtime39 = require("react/jsx-runtime");
var LiveSalesInfoItems = ({ items: items2 }) => {
  const theme = useTheme();
  const styles3 = (0, import_react35.useMemo)(() => {
    return import_react_native40.StyleSheet.create({
      container: {
        flexDirection: "column",
        gap: theme?.media.isMobile ? 4 : 8,
        textAlign: "right"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(import_react_native40.View, { style: styles3.container, children: items2.map(({ title, value }) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(LiveSalesInfoItem, { title, value }, title)) });
};

// src/components/pages/live/index.ts
var LineItemCardSizes = {
  mobile: {
    width: 199,
    height: 76
  },
  desktop: {
    width: 380,
    height: 92
  }
};

// src/components/pages/stores/StoreHeaderTitle.tsx
var import_react36 = require("react");
var import_io55 = require("react-icons/io5");
var import_react_native41 = require("react-native");
var import_jsx_runtime40 = require("react/jsx-runtime");
var StoreHeaderTitle = ({ title, handleBackNavigate }) => {
  const theme = useTheme();
  const styles3 = (0, import_react36.useMemo)(() => {
    return import_react_native41.StyleSheet.create({
      container: {
        width: "100%",
        maxWidth: theme?.media.isMobile ? 340 : 640,
        marginHorizontal: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      icnoContainer: {
        backgroundColor: "transparent"
      },
      title: {
        color: theme?.media.isMobile ? theme.color.secondaryGray : theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.lg
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)(import_react_native41.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(ButtonWithChildren, { style: styles3.icnoContainer, onPress: handleBackNavigate, children: /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_io55.IoChevronBack, { size: 16, color: theme?.color.secondaryWhite }) }),
    /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(Text3, { style: styles3.title, children: title }),
    /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_react_native41.View, {})
  ] });
};

// src/components/pages/stores/StoreKeyBaseInfo.tsx
var import_react_native42 = require("react-native");
var import_jsx_runtime41 = require("react/jsx-runtime");
var StoreKeyBaseInfo = ({ currentKey, redirectPaperPage }) => {
  const theme = useTheme();
  const styles3 = import_react_native42.StyleSheet.create({
    name: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.md
    },
    price: {
      marginTop: 8,
      color: theme?.color.mint,
      fontSize: theme?.fontSize["2xl"]
    },
    left: {
      marginTop: 8,
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.sm
    },
    redirectPaperButton: {
      marginTop: 16,
      paddingHorizontal: 40,
      paddingVertical: 16
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_react_native42.View, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(Text3, { style: styles3.name, children: [
      currentKey.name,
      " Key"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(Text3, { style: styles3.price, children: [
      "$ ",
      currentKey.price,
      " / NET"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
      PrimaryButton,
      {
        customStyle: styles3.redirectPaperButton,
        title: "Become an Owner",
        onPress: () => redirectPaperPage(currentKey.paperStoreUrl)
      }
    )
  ] });
};

// src/components/pages/stores/StoreKeyDescriptionItem.tsx
var import_react37 = require("react");
var import_bs5 = require("react-icons/bs");
var import_react_native43 = require("react-native");
var import_jsx_runtime42 = require("react/jsx-runtime");
var StoreKeyDescriptionItem = ({ currentKey }) => {
  const theme = useTheme();
  const styles3 = (0, import_react37.useMemo)(() => {
    return import_react_native43.StyleSheet.create({
      container: {
        padding: 32,
        backgroundColor: theme?.color.secondaryWhite,
        borderRadius: 32
      },
      hero: {
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      detailsContainer: {
        marginTop: 16,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        backgroundColor: theme?.color.secondaryLight
      },
      detailsContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: theme?.fontSize.sm
      },
      addressContainer: {
        width: 120
      },
      address: {
        width: theme?.media.isMobile ? 100 : 160,
        fontWeight: "bold"
      },
      detailText: {
        fontWeight: "bold"
      },
      allowsOwnerHero: {
        marginTop: 32,
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      allowsOwnerContainer: {
        marginTop: 16,
        paddingTop: 16,
        display: "flex",
        flexDirection: "column",
        gap: 16
      },
      allowsOwnerContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      },
      allowOwnerText: {
        marginLeft: 16,
        fontSize: theme?.fontSize.md
      },
      iconContainer: {
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.secondaryLiteGray
      },
      activeIconContainer: {
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.mint
      }
    });
  }, []);
  const {
    address,
    standart,
    blockchain,
    isGovernanceRights,
    isVotingRight,
    isPartnerRight,
    isAccesibleBoard,
    isManager
  } = currentKey;
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.hero, children: "NFT Details:" }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.addressContainer, children: "Contract Address" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.address, numberOfLines: 1, children: address })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { children: "Taken Standart" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.detailText, children: standart })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { children: "Blockchain" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.detailText, children: blockchain })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowsOwnerHero, children: "This ownership allows you to be an executive owner:" }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isGovernanceRights ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "3x Governance rights" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isVotingRight ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "Voting on which brand Destore sells" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isPartnerRight ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "The right to accept brands as Destore partner" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isAccesibleBoard ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "Access to an executive board" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isManager ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "Manage financial resources and inventory" })
      ] })
    ] })
  ] });
};

// src/components/pages/stores/StoreKeyList.tsx
var import_react_native45 = require("react-native");

// src/components/pages/stores/StoreKeyListItem.tsx
var import_react38 = require("react");
var import_react_native44 = require("react-native");
var import_jsx_runtime43 = require("react/jsx-runtime");
var StoreKeyListItem = ({
  index,
  isLoggedIn,
  keyData,
  handleNavigateBuyNFT,
  handleShowConfirmModal
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react38.useMemo)(() => {
    return import_react_native44.StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 150 : "auto",
        maxWidth: 150,
        marginLeft: index > 0 ? 10 : 0,
        padding: 16,
        display: "flex",
        flexGrow: theme?.media.isMobile ? 0 : 1,
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        textAlign: "center"
      },
      keyImage: {
        marginHorizontal: "auto"
      },
      name: {
        marginTop: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      },
      price: {
        marginTop: 4,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.xl
      },
      left: {
        marginTop: 4,
        color: theme?.color.secondaryLiteGray,
        fontSize: theme?.fontSize.xs
      },
      selectButton: {
        marginTop: 16,
        paddingHorizontal: 16,
        paddingVertical: 8
      }
    });
  }, [theme?.media.isMobile]);
  const { visual, name, price, tokenId, store: store2 } = keyData;
  return /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(import_react_native44.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(Image, { source: { uri: visual, width: 80, height: 120 }, style: styles3.keyImage }),
    /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(Text3, { style: styles3.name, children: [
      name,
      " Key"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(Text3, { style: styles3.price, children: [
      "$ ",
      price
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(
      PrimaryButton,
      {
        customStyle: styles3.selectButton,
        title: "Select",
        onPress: isLoggedIn ? () => handleNavigateBuyNFT(store2.url, tokenId) : handleShowConfirmModal
      }
    )
  ] });
};

// src/components/pages/stores/StoreKeyList.tsx
var import_jsx_runtime44 = require("react/jsx-runtime");
var StoreKeyList = ({ isLoggedIn, keys, handleNavigateBuyNFT, handleShowConfirmModal }) => {
  const theme = useTheme();
  const styles3 = import_react_native45.StyleSheet.create({
    ownershipContainer: {
      marginTop: theme?.media.isMobile ? 32 : 72,
      paddingLeft: theme?.media.isMobile ? 32 : 0
    },
    ownershipText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.md
    },
    keyContainer: {
      marginTop: 16,
      display: "flex",
      flexDirection: "row",
      overflowX: "scroll"
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime44.jsxs)(import_jsx_runtime44.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(Text3, { style: styles3.ownershipText, children: "Membership on sale:" }),
    /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(import_react_native45.View, { style: styles3.keyContainer, children: keys.map((keyData, i) => /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
      StoreKeyListItem,
      {
        index: i,
        isLoggedIn,
        keyData,
        handleNavigateBuyNFT,
        handleShowConfirmModal
      },
      keyData.name
    )) })
  ] });
};

// src/components/pages/stores/StoreListItem.tsx
var import_react39 = require("react");
var import_react_native46 = require("react-native");
var import_jsx_runtime45 = require("react/jsx-runtime");
var StoreListItem = ({ index, store: store2, handleStoreNavigate }) => {
  const theme = useTheme();
  const styles3 = (0, import_react39.useMemo)(() => {
    return import_react_native46.StyleSheet.create({
      container: {
        marginTop: theme?.media.isMobile ? index === 0 ? 16 : 48 : index === 0 ? 72 : 48
      },
      imageContainer: {
        position: "relative"
      },
      grayBackground: {
        width: "100%",
        height: theme?.media.isMobile ? 164 : 320,
        borderRadius: 40,
        backgroundColor: "rgba(17, 16, 16, 0.6)",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1
      },
      comingText: {
        width: 114,
        paddingHorizontal: 20,
        paddingVertical: 8,
        borderTopEndRadius: theme?.radius.lg,
        backgroundColor: "rgba(255, 255, 255, 0.26)",
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.xs,
        position: "absolute",
        top: theme?.media.isMobile ? 64 : 138,
        left: "calc(50% - 57px)",
        zIndex: 2
      },
      visualImage: {
        width: "100%",
        borderRadius: 40
      },
      descriptionContainer: {
        width: "100%",
        marginTop: 20,
        textAlign: "center"
      },
      name: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["2xl"]
      },
      place: {
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.xs
      },
      buyButton: {
        width: "max-content",
        marginTop: 20,
        marginHorizontal: "auto",
        paddingHorizontal: 40,
        paddingVertical: 12,
        display: "flex",
        borderRadius: theme?.radius.full,
        fontSize: theme?.fontSize.sm,
        textAlign: "center"
      }
    });
  }, [index, theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_react_native46.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_react_native46.View, { style: styles3.imageContainer, children: [
      !store2.isActive && /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_jsx_runtime45.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(import_react_native46.View, { style: styles3.grayBackground }),
        /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Text3, { style: styles3.comingText, children: "Coming soon" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Image, { source: { uri: store2.visual, height: theme?.media.isMobile ? 164 : 320 }, style: styles3.visualImage })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_react_native46.View, { style: styles3.descriptionContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Text3, { style: styles3.name, children: store2.name }),
      /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Text3, { style: styles3.place, children: store2.place }),
      store2.isActive && /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
        PrimaryButton,
        {
          customStyle: styles3.buyButton,
          title: "Go to store",
          onPress: () => handleStoreNavigate(store2.id, store2.name)
        }
      )
    ] })
  ] }, store2.id);
};

// src/components/pages/stores/StoreNFTsBaseInfo.tsx
var import_react40 = require("react");
var import_react_native47 = require("react-native");
var import_jsx_runtime46 = require("react/jsx-runtime");
var StoreNFTsBaseInfo = ({ store: store2 }) => {
  const [isTextOpen, setIsTextOpen] = (0, import_react40.useState)(true);
  const theme = useTheme();
  const targetBreakPoint = theme?.breakpoint.md || 0;
  const baseRowCount = window.innerWidth > targetBreakPoint ? 2 : 4;
  const styles3 = (0, import_react40.useMemo)(() => {
    return import_react_native47.StyleSheet.create({
      storeInfoContainer: {
        width: "100%"
      },
      storeBaseInfo: {
        paddingHorizontal: theme?.media.isMobile ? 32 : 0,
        textAlign: "center"
      },
      name: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["5xl"]
      },
      place: {
        marginTop: 12,
        color: theme?.color.secondaryWhite
      },
      title: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      descriptionBox: {
        marginTop: 40,
        paddingHorizontal: theme?.media.isMobile ? 32 : 0
      },
      contentBox: {
        marginTop: theme?.media.isMobile ? 40 : 24,
        paddingHorizontal: theme?.media.isMobile ? 32 : 0
      },
      content: {
        marginTop: 8,
        color: theme?.color.secondaryWhite
      },
      reason: {
        marginTop: 8,
        color: theme?.color.secondaryWhite
      },
      readMore: {
        marginTop: 8,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.sm,
        fontWeight: "bold",
        textDecorationLine: "underline"
      }
    });
  }, [theme?.media.isMobile]);
  (0, import_react40.useEffect)(() => {
    const target = document.getElementById("reason-content");
    if (target === null)
      return;
    const textHeight = target.clientHeight;
    let lineHeight = getComputedStyle(target).getPropertyValue("line-height");
    lineHeight = lineHeight.replace(/[^-\d.]/g, "");
    if (textHeight > Number(lineHeight) * baseRowCount) {
      setIsTextOpen(false);
    }
  }, [theme]);
  const handlePressReadMore = () => {
    setIsTextOpen(true);
  };
  const { name, place, description, reason } = store2;
  return /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(import_jsx_runtime46.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.storeInfoContainer, children: [
    /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.storeBaseInfo, children: [
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.name, children: name }),
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.place, children: place })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.descriptionBox, children: [
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.title, children: "Description:" }),
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.content, children: description })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.contentBox, children: [
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.title, children: "Meet the owner:" }),
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.content, numberOfLines: isTextOpen ? void 0 : baseRowCount, nativeID: "reason-content", children: reason }),
      !isTextOpen && /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.readMore, onPress: handlePressReadMore, children: "Read More" })
    ] })
  ] }) });
};

// src/components/pages/stores/StoreNFTsVisualImage.tsx
var import_react41 = require("react");
var import_react_native48 = require("react-native");
var import_jsx_runtime47 = require("react/jsx-runtime");
var StoreNFTsVisualImage = ({ storeVisualImage }) => {
  const theme = useTheme();
  const styles3 = (0, import_react41.useMemo)(() => {
    return import_react_native48.StyleSheet.create({
      imageContainer: {
        width: "100%",
        marginTop: theme?.media.isMobile ? 0 : 48,
        position: "relative"
      },
      storeImage: {
        width: "100%",
        borderRadius: theme?.media.isMobile ? 0 : 40,
        opacity: theme?.media.isMobile ? 0.8 : 1
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_react_native48.View, { style: styles3.imageContainer, children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(Image, { source: { uri: storeVisualImage, height: theme?.media.isMobile ? 360 : 420 }, style: styles3.storeImage }) });
};

// src/components/pages/stores/DeployedContractItem.tsx
var import_react42 = require("react");
var import_react_native49 = require("react-native");
var import_jsx_runtime48 = require("react/jsx-runtime");
var DeployedContractItem = ({
  index,
  handleNavigateToScanPage,
  contractType,
  address
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react42.useMemo)(() => {
    return import_react_native49.StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 150 : "auto",
        maxWidth: theme?.media.isMobile ? 150 : 180,
        marginLeft: theme?.media.isMobile ? index % 2 !== 0 ? 10 : 0 : index > 0 ? 10 : 0,
        marginBottom: theme?.media.isMobile ? 10 : 0,
        padding: 16,
        flexGrow: 1,
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        textAlign: "center"
      },
      contractType: {
        marginHorizontal: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      },
      address: {
        marginTop: 4,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.xxs
      },
      selectButton: {
        marginTop: 16,
        paddingVertical: 8,
        borderRadius: 16,
        width: "100%",
        alignItems: "center",
        color: theme?.color.secondaryWhite
      }
    });
  }, [theme?.media.isMobile]);
  const abbreviatedAddress = (0, import_react42.useMemo)(() => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  }, [address]);
  return /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)(import_react_native49.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(Text3, { style: styles3.contractType, numberOfLines: 1, children: contractType }),
    /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(Text3, { style: styles3.address, children: abbreviatedAddress }),
    /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.selectButton,
        onPress: () => handleNavigateToScanPage(address),
        buttonColor: theme?.color.secondaryLight,
        children: /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(Text3, { style: { color: theme?.color.black, fontSize: theme?.fontSize.xxs }, children: "View on PolygonScan" })
      }
    )
  ] });
};

// src/components/pages/stores/DeployedContractsList.tsx
var import_react_native50 = require("react-native");
var import_jsx_runtime49 = require("react/jsx-runtime");
var DeployedContractsList = ({ store: store2, handleNavigateToScanPage }) => {
  const theme = useTheme();
  const styles3 = import_react_native50.StyleSheet.create({
    titleText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.lg
    },
    container: {
      marginTop: 24,
      display: "flex",
      flexDirection: "row",
      flexWrap: theme?.media.isMobile ? "wrap" : "nowrap",
      alignContent: theme?.media.isMobile ? "flex-start" : "stretch",
      width: theme?.media.isMobile ? 360 : "auto",
      overflowX: theme?.media.isMobile ? "hidden" : "scroll"
    }
  });
  const { deployedAddresses } = store2;
  return /* @__PURE__ */ (0, import_jsx_runtime49.jsxs)(import_jsx_runtime49.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(Text3, { style: styles3.titleText, children: "Deployed Contract:" }),
    /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(import_react_native50.View, { style: styles3.container, children: deployedAddresses && Object.entries(deployedAddresses).map(([key, value], i) => /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(
      DeployedContractItem,
      {
        index: i,
        contractType: key,
        address: value,
        handleNavigateToScanPage
      }
    )) })
  ] });
};

// src/components/pages/stores/ActiveEventsList.tsx
var import_react_native52 = require("react-native");

// src/components/pages/stores/ActiveEventItem.tsx
var import_react43 = require("react");
var import_react_native51 = require("react-native");
var import_jsx_runtime50 = require("react/jsx-runtime");
var ActiveEventItem = ({ index, handleNavigateEventPage, event }) => {
  const theme = useTheme();
  const styles3 = (0, import_react43.useMemo)(() => {
    return import_react_native51.StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 150 : "auto",
        maxWidth: theme?.media.isMobile ? 150 : 180,
        marginLeft: theme?.media.isMobile ? index % 2 !== 0 ? 10 : 0 : index > 0 ? 10 : 0,
        marginBottom: theme?.media.isMobile ? 10 : 0,
        padding: 16,
        flexGrow: 1,
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        textAlign: "center"
      },
      contractType: {
        marginHorizontal: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      },
      address: {
        marginTop: 4,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.xxs
      },
      selectButton: {
        marginTop: 16,
        paddingVertical: 8,
        borderRadius: 16,
        width: "100%",
        alignItems: "center",
        color: theme?.color.secondaryWhite
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)(import_react_native51.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(Text3, { style: styles3.contractType, numberOfLines: 1, children: event.eventName }),
    event.eventDetails.map((eventDetail, detailIndex) => /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.selectButton,
        onPress: () => handleNavigateEventPage(eventDetail.url),
        buttonColor: detailIndex === 0 ? theme?.color.mint : theme?.color.secondaryLight,
        children: /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(Text3, { style: { color: theme?.color.black, fontSize: theme?.fontSize.xxs }, children: eventDetail.description })
      }
    ))
  ] });
};

// src/components/pages/stores/ActiveEventsList.tsx
var import_jsx_runtime51 = require("react/jsx-runtime");
var ActiveEventsList = ({ store: store2, handleNavigateEventPage }) => {
  const theme = useTheme();
  const styles3 = import_react_native52.StyleSheet.create({
    titleText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.lg
    },
    container: {
      marginTop: 24,
      display: "flex",
      flexDirection: "row",
      flexWrap: theme?.media.isMobile ? "wrap" : "nowrap",
      alignContent: theme?.media.isMobile ? "flex-start" : "stretch",
      width: theme?.media.isMobile ? 360 : "auto",
      overflowX: theme?.media.isMobile ? "hidden" : "scroll"
    }
  });
  const { activeEvent } = store2;
  return /* @__PURE__ */ (0, import_jsx_runtime51.jsxs)(import_jsx_runtime51.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(Text3, { style: styles3.titleText, children: "Active events:" }),
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(import_react_native52.View, { style: styles3.container, children: activeEvent && activeEvent.map((event, i) => /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(ActiveEventItem, { index: i, event, handleNavigateEventPage })) })
  ] });
};

// src/components/pages/settings/SettingsHeaderCenterChildren.tsx
var import_react44 = require("react");
var import_react_native53 = require("react-native");
var import_jsx_runtime52 = require("react/jsx-runtime");
var SettingsHeaderCenterChildren = ({ avatarPath, name }) => {
  const theme = useTheme();
  const styles3 = (0, import_react44.useMemo)(() => {
    return import_react_native53.StyleSheet.create({
      avatar: {
        width: 64,
        height: 64,
        borderRadius: 125
      },
      name: {
        fontSize: 22,
        lineHeight: 28,
        marginTop: 16,
        color: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(import_jsx_runtime52.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
      Image,
      {
        source: {
          uri: avatarPath
        },
        style: styles3.avatar,
        resizeMode: "cover"
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Text3, { style: styles3.name, children: name })
  ] });
};

// src/components/pages/settings/SettingsItem.tsx
var import_react45 = require("react");
var import_react_native54 = require("react-native");
var import_jsx_runtime53 = require("react/jsx-runtime");
var SettingsItem = ({ chevronRightIconPath: chevronRightIconPath2, icon, onPress, title }) => {
  const theme = useTheme();
  const styles3 = (0, import_react45.useMemo)(() => {
    return import_react_native54.StyleSheet.create({
      button: {
        width: "100%"
      },
      buttonChildrenContainer: {
        width: "100%",
        height: 76,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: theme?.color.secondaryLight,
        alignItems: "center",
        paddingHorizontal: 16
      },
      horizontalContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 16
      },
      iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24
      },
      titleContainer: {
        fontSize: 16,
        lineHeight: 20,
        color: theme?.color.primaryBlack
      },
      chevronRightIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 25
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(import_react_native54.Pressable, { style: styles3.button, onPress, children: /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(import_react_native54.View, { style: styles3.buttonChildrenContainer, children: [
    /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(import_react_native54.View, { style: styles3.horizontalContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(import_react_native54.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(Image, { source: { uri: icon.path, height: icon.height, width: icon.width } }) }),
      /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(Text3, { style: styles3.titleContainer, children: title })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(import_react_native54.View, { style: styles3.chevronRightIconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(Image, { source: { uri: chevronRightIconPath2, height: 12, width: 6 } }) })
  ] }) });
};

// src/components/pages/settings/SettingsItems.tsx
var import_react46 = require("react");
var import_react_native55 = require("react-native");
var import_jsx_runtime54 = require("react/jsx-runtime");
var SettingsItems = ({ chevronRightIconPath: chevronRightIconPath2, items: items2, version }) => {
  const theme = useTheme();
  const styles3 = (0, import_react46.useMemo)(() => {
    return import_react_native55.StyleSheet.create({
      verticalContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 16,
        gap: 8,
        width: "100%",
        maxWidth: 820
      },
      versionContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 80
      },
      version: {
        fontSize: 12,
        lineHeight: 16,
        color: theme?.color.secondaryLiteGray
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime54.jsxs)(import_react_native55.View, { style: styles3.verticalContainer, children: [
    items2.map((item) => /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(SettingsItem, { chevronRightIconPath: chevronRightIconPath2, ...item }, item.title)),
    /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(import_react_native55.View, { style: styles3.versionContainer, children: /* @__PURE__ */ (0, import_jsx_runtime54.jsxs)(Text3, { style: styles3.version, children: [
      "DeStore. Version ",
      version
    ] }) })
  ] });
};

// src/components/pages/proposals/ProposalsUserRequirementSection.tsx
var import_react47 = require("react");
var import_fi4 = require("react-icons/fi");
var import_react_native56 = require("react-native");
var import_jsx_runtime55 = require("react/jsx-runtime");
var ProposalsUserRequirementSection = ({
  hasVoted,
  inCorrectVotingPeriod,
  isLoggedIn,
  isLoading,
  handleShowConnectModal
}) => {
  const theme = useTheme();
  let titleText = "";
  let subText = "";
  let containerColor = theme?.color.secondaryLightGreen;
  let IconComponent = import_fi4.FiLoader;
  if (!isLoggedIn) {
    titleText = "Please connect your wallet.";
    subText = "You need to be logged in to vote.";
    containerColor = theme?.color.gray;
    IconComponent = import_fi4.FiAlertCircle;
  } else if (inCorrectVotingPeriod) {
    titleText = "Voting period has already ended.";
    subText = "You can vote for this week\u2019s proposal.";
    containerColor = theme?.color.gray;
    IconComponent = import_fi4.FiAlertCircle;
  } else if (isLoading) {
    titleText = "Loading your wallet info.";
    subText = "Wait for a moment.";
    containerColor = theme?.color.gray;
    IconComponent = import_fi4.FiAlertCircle;
  } else if (isLoggedIn && hasVoted && !inCorrectVotingPeriod) {
    titleText = "Thank you for voting!";
    subText = "You have already voted for this week's proposal.";
    containerColor = theme?.color.pink;
    IconComponent = import_fi4.FiCheckCircle;
  } else if (isLoggedIn && !hasVoted && !inCorrectVotingPeriod) {
    titleText = "Vote now!";
    subText = "You can vote for this week\u2019s proposal.";
    containerColor = theme?.color.secondaryLightGreen;
    IconComponent = import_fi4.FiChevronsDown;
  }
  const styles3 = (0, import_react47.useMemo)(() => {
    return import_react_native56.StyleSheet.create({
      container: {
        width: "100%",
        height: theme?.media.isMobile ? 100 : 150,
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        justifyContent: "flex-start",
        borderRadius: 16,
        borderColor: theme?.color.mint
      },
      inner: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)",
        textAlign: "left"
      },
      title: {
        fontSize: theme?.media.isMobile ? theme.fontSize.lg : theme?.fontSize["xl"],
        fontWeight: "bold"
      },
      subText: {
        marginTop: 8,
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.md
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.dark
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
    ButtonWithChildren,
    {
      customStyle: [styles3.container, { backgroundColor: containerColor }],
      buttonColor: containerColor,
      onPress: !isLoggedIn ? handleShowConnectModal : void 0,
      children: /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(import_react_native56.View, { style: styles3.inner, children: [
        /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(import_react_native56.View, { style: styles3.textContainer, children: [
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(Text3, { style: styles3.title, children: titleText }),
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(Text3, { style: styles3.subText, children: subText })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(import_react_native56.View, { style: styles3.iconContainer, children: import_react_native56.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(IconComponent, { color: theme?.color.secondaryWhite, size: 22 }) : null })
      ] })
    }
  );
};

// src/components/pages/proposals/ProposalRequirementSection.tsx
var import_react48 = require("react");
var import_fi5 = require("react-icons/fi");
var import_react_native57 = require("react-native");
var import_jsx_runtime56 = require("react/jsx-runtime");
var ProposalRequirementSection = ({
  startTime,
  endTime,
  voteContractAddress
}) => {
  const theme = useTheme();
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1e3);
    return date.toLocaleString();
  };
  const scanUrl = () => {
    if (process.env.REACT_APP_VERCEL_ENV === "production") {
      return `https://polygonscan.com/address/${voteContractAddress}`;
    } else {
      return `https://mumbai.polygonscan.com/address/${voteContractAddress}`;
    }
  };
  const openScanUrl = () => {
    const url = scanUrl();
    import_react_native57.Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        import_react_native57.Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: " + url);
      }
    });
  };
  const styles3 = (0, import_react48.useMemo)(() => {
    return import_react_native57.StyleSheet.create({
      container: {
        width: "100%",
        height: theme?.media.isMobile ? 100 : 150,
        paddingHorizontal: theme?.media.isMobile ? 4 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        marginVertical: theme?.media.isMobile ? 20 : 32,
        marginLeft: theme?.media.isMobile ? 4 : 32,
        justifyContent: "flex-start",
        borderRadius: 16,
        borderColor: theme?.color.mint
      },
      inner: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)"
      },
      title: {
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize["md"],
        fontWeight: "bold",
        textAlign: "left"
      },
      subText: {
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.sm,
        textAlign: "left"
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.dark
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(ButtonWithChildren, { customStyle: styles3.container, buttonColor: theme?.color.secondaryLight, children: /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(import_react_native57.View, { style: styles3.inner, children: [
    /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(import_react_native57.View, { style: styles3.textContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(Text3, { style: styles3.title, children: "Voting periods:" }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(Text3, { style: styles3.subText, children: [
        formatDate(startTime),
        "~",
        formatDate(endTime)
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(Text3, { style: styles3.title, children: "Voting contract detail:" }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(Text3, { style: styles3.subText, onPress: openScanUrl, children: voteContractAddress })
    ] }),
    import_react_native57.Platform.OS === "web" && !theme?.media.isMobile ? /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_react_native57.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_react_native57.TouchableOpacity, { onPress: openScanUrl, children: /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_fi5.FiArrowUpRight, { color: theme?.color.secondaryWhite, size: 22 }) }) }) : null
  ] }) });
};

// src/mocks/keys.ts
var KeyData = {
  data: [
    // {
    //   tokenId: 0,
    //   visual: '/key/key_rainbow.gif',
    //   name: 'Raibnow',
    //   price: 0.59,
    //   left: 30,
    //   address: '0x8cF3084E9B634272577BA5BfD4aA07b93Bd5eC6a',
    //   standart: 'ERC-1155',
    //   blockchain: 'Polygon',
    //   isGovernanceRights: true,
    //   isVotingRight: true,
    //   isPartnerRight: true,
    //   isAccesibleBoard: true,
    //   isManager: true,
    //   paperStoreUrl: 'https://paper.xyz/checkout/56145944-6ca3-496c-ac22-bfa4e0675ebe',
    //   store: {
    //     id: 1,
    //     url: 'Zero',
    //   },
    // },
    // {
    //   tokenId: 1,
    //   visual: '/key/key_golden.gif',
    //   name: 'Golden',
    //   price: 0.19,
    //   address: '0x8cF3084E9B634272577BA5BfD4aA07b93Bd5eC6a',
    //   standart: 'ERC-1155',
    //   blockchain: 'Polygon',
    //   isGovernanceRights: true,
    //   isVotingRight: true,
    //   isPartnerRight: true,
    //   isAccesibleBoard: false,
    //   isManager: false,
    //   paperStoreUrl: 'https://paper.xyz/checkout/be075c82-e4eb-45bc-8496-232ed1521a95',
    //   store: {
    //     id: 1,
    //     url: 'Zero',
    //   },
    // },
    {
      tokenId: 0,
      visual: "/key/key_silver.gif",
      name: "Silver",
      price: 29,
      address: "0xC87738Be56f29a0a2872De046cb4e3dEB9f9aA65",
      standart: "ERC-1155",
      blockchain: "Polygon",
      isGovernanceRights: true,
      isVotingRight: true,
      isPartnerRight: false,
      isAccesibleBoard: false,
      isManager: false,
      paperStoreUrl: "https://withpaper.com/checkout/b6792614-d70e-49c3-bb5a-5b44e2c3ea04",
      store: {
        id: 1,
        url: "StoreZero"
      }
    }
  ]
};

// src/mocks/orders.ts
var LineItemData = {
  data: [
    {
      id: "1",
      quantity: 1,
      price: 36,
      currencyCode: "USD",
      updatedAt: "1 hour ago",
      name: "Organic Skin Loation",
      vendor: "705 Origin"
    },
    {
      id: "2",
      quantity: 2,
      price: 92,
      currencyCode: "USD",
      updatedAt: "26 min ago",
      name: "Organic Skin Milk",
      vendor: "705 Origin"
    },
    {
      id: "3",
      quantity: 1,
      price: 65,
      currencyCode: "USD",
      updatedAt: "3 min ago",
      name: "705 Black Sweat",
      vendor: "705 Origin"
    },
    {
      id: "4",
      quantity: 1,
      price: 65,
      currencyCode: "USD",
      updatedAt: "3 min ago",
      name: "705 Black Sweat",
      vendor: "705 Origin"
    }
  ]
};

// src/mocks/proposal.ts
var ProposalMenuAppData = {
  data: [
    {
      imagePath: "/logo/logo_icon_black.svg",
      name: "Create special Proposal",
      description: "Make  a proposal for sellection brand and product",
      upRightArrowImagePath: "/icon/u_pen.svg"
    },
    {
      imagePath: "/logo/logo_tallyxyz.png",
      name: "Create general Proposal",
      description: "Make proposal for general purpose for DAO at Tally.xyz",
      upRightArrowImagePath: "/icon/fi_link.svg"
    },
    {
      imagePath: "/logo/logo_tallyxyz.png",
      name: "View Governance",
      description: "View governance for DAO at Tally.xyz ",
      upRightArrowImagePath: "/icon/fi_link.svg"
    }
  ]
};

// src/mocks/settings.ts
var ProfileData = {
  name: "Datz Daito",
  avatarPath: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
};
var items = [
  {
    icon: {
      height: 20,
      path: "/icon/user.svg",
      width: 20
    },
    title: "My Account"
  },
  {
    icon: {
      height: 21,
      path: "/icon/shopping-cart.svg",
      width: 22
    },
    title: "Store List"
  },
  {
    icon: {
      height: 20,
      path: "/icon/bell.svg",
      width: 18
    },
    title: "Notification"
  },
  {
    icon: {
      height: 20,
      path: "/icon/help-circle.svg",
      width: 20
    },
    title: "Support"
  }
];
var chevronRightIconPath = "/icon/chevron-right.svg";
var SettingsItemsPropsData = {
  items,
  chevronRightIconPath,
  version: "1.0"
};

// src/mocks/stores.ts
var storeZeroActiveEvent = [
  {
    url: "proposals/this-week",
    description: "This Week"
  },
  {
    url: "proposals/last-week",
    description: "Last Week"
  }
];
var StoreData = {
  data: [
    {
      id: 1,
      visual: "/store/destore-zero.png",
      name: "StoreZero",
      url: "StoreZero",
      isActive: true,
      place: "348 Hayes Street, San Francisco",
      mission: "build the world\u2019s first DAO-owned retail store.",
      description: "Zero is the most interactive store in the world.A curated blend of new and pre-owned items, handpicked by Datz and Dax, featuring apparel, accessories, and lifestyle goods to cater to a creative lifestyle.",
      reason: "Datz is the first fashion YouTuber in Japan since 15 y.o.Featured as one of the top 10 fashion YouTubers by the largest fashion media in Japan with 1M+ views in total.Collaborated with Dax (from Curated By Dax, the best showroom in NYC), Zero is handpicking niche designer pieces in an inclusive way at the space.",
      deployedAddresses: {
        Treasury: storeZeroAddresses().TREASURY_ADDRESS,
        StoreManager: storeZeroAddresses().STORE_MANAGER_ADDRESS,
        StoreVote: storeZeroAddresses().VOTE_CONTRACT_ADDRESS
      },
      activeEvent: [
        {
          eventName: "Proposals",
          eventDetails: storeZeroActiveEvent
        }
      ],
      logoImagePathSecondaryWhite: "/mock/store_logo_secondary_white.svg",
      logoImagePathPrimaryBlack: "/mock/store_logo_primary_black.svg"
    },
    {
      id: 2,
      visual: "/store/destore-next.png",
      name: "????",
      url: "",
      isActive: false,
      place: "348 Hayes Street, San Francisco",
      mission: "build the world\u2019s first DAO-owned retail store.",
      description: "SF Destore was successfully leased at 348 Hayes St, San Francisco. It's in front of Away, Warby Parker, and Mejuri. The area is called Hayes Valley, where a number of modern luxury brands dream opening their flag ships.",
      reason: "The key of the retail is the community.The distance between consumers and owners are getting closer these eras.However, there has been a limitation to be beyond evangelist as a consumer.\n\nThat is where DeStore come from with utlizing crypto technolgies.\n\nNow, consumers can easily become owners.\n\nAt the dawn of the crypto age, global of creative enthusiatsts come together to define the future of retail starting at SF Destore.\n\nAfter the success of SF Destore, there will be many of next level of bottom-up community driven store folliwing you.\n\nSTORE_0 is going to be the historical first DAO-owned store in the world.",
      logoImagePathSecondaryWhite: "/mock/store_logo.svg",
      logoImagePathPrimaryBlack: "/mock/store_logo_primary_black.svg"
    }
  ]
};
var StoreMenuAppData = {
  data: [
    {
      imagePath: "/icon/discord.svg",
      name: "Discode",
      description: "Manage roles & guard server",
      upRightArrowImagePath: "/icon/up-right-arrow.svg"
    },
    {
      imagePath: "/icon/snapshot.svg",
      name: "Snapshot",
      description: "Manage roles & guard server",
      upRightArrowImagePath: "/icon/up-right-arrow.svg"
    },
    {
      imagePath: "/icon/notion.svg",
      name: "Notion",
      description: "Manage roles & guard server",
      upRightArrowImagePath: "/icon/up-right-arrow.svg"
    }
  ]
};
var StoreSalesInfoData = {
  data: [
    {
      title: "Daily Sales",
      value: "$320"
    },
    {
      title: "Daily Customers",
      value: "80"
    },
    {
      title: "Daily Sales Target",
      value: "$500"
    }
  ]
};
var StoreLiveStreamingData = {
  data: [
    {
      playbackUrl: process.env["REACT_APP_PLAYBACK_URL"] ?? "",
      internalGif: "/mock/live.gif"
    }
    // {
    //   playbackUrl: process.env['REACT_APP_PLAYBACK_URL'] ?? '',
    //   internalGif: '/mock/live.gif',
    // },
    // {
    //   playbackUrl: process.env['REACT_APP_PLAYBACK_URL'] ?? '',
    //   internalGif: '/mock/live.gif',
    // },
  ]
};
var StoreIndicatorsData = {
  data: {
    treasuryBalance: "$1,000",
    storeBalance: "$45,000",
    expense: "$3,563",
    grossSales: "125",
    members: "382",
    profit: "$3,563"
  }
};
var BrandSalesRankingData = {
  data: [
    { brandName: "705 Origin", sales: 7500 },
    { brandName: "Adidas", sales: 7e3 },
    { brandName: "Hellaco", sales: 6500 },
    { brandName: "ADLAR", sales: 6e3 },
    { brandName: "Klark", sales: 5500 },
    { brandName: "Force Majeure", sales: 75 }
  ]
};
var SalesAndExpenseData = {
  data: [
    {
      sales: 5e3,
      expense: 3e3,
      month: "Jan"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Feb"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Mar"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Apr"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "May"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Jun"
    }
  ]
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ActiveEventItem,
  ActiveEventsList,
  AppsModal,
  AppsSlide,
  AuthorizationSubHeaderForProposal,
  AuthorizedByNFTHeader,
  BrandSalesRankingData,
  Button,
  ButtonWithChildren,
  ConnectWalletModal,
  ContainerLayout,
  DashboardIndicators,
  DashboardTabview,
  DeStoreThemeProvider,
  DeployedContractItem,
  DeployedContractsList,
  ExploreDeStoreModal,
  Header,
  HomeCta,
  HomeEnterDeStoreSection,
  HomeExploreDeStoreSection,
  Image,
  InitSpinner,
  KeyData,
  LineItemData,
  Link,
  LiveHeaderCenterChildren,
  LiveLineItems,
  LiveSalesInfoItem,
  LiveSalesInfoItems,
  MobileHeader,
  Modal,
  NetworkDifferentModal,
  OutlineButton,
  PrimaryButton,
  ProfileData,
  ProposalMenuAppData,
  ProposalRequirementSection,
  ProposalsUserRequirementSection,
  SalesAndExpenseData,
  SettingsHeaderCenterChildren,
  SettingsItem,
  SettingsItems,
  SettingsItemsPropsData,
  Spacer,
  Spinner,
  StoreData,
  StoreHeaderTitle,
  StoreIndicatorsData,
  StoreKeyBaseInfo,
  StoreKeyDescriptionItem,
  StoreKeyList,
  StoreKeyListItem,
  StoreListItem,
  StoreLiveStreamingData,
  StoreMenuAppData,
  StoreNFTsBaseInfo,
  StoreNFTsVisualImage,
  StoreSalesInfoData,
  TabGroupSpacer,
  TabOverlay,
  Text,
  Toasts,
  WalletConfirmModal,
  closeAllModal,
  connectWallet,
  disconnectWallet,
  hideToast,
  paperAuthLoadingEnd,
  paperAuthLoadingStart,
  showConfirmModal,
  showConnectModal,
  showToast,
  store,
  storeZeroAddresses,
  useAppDispatch,
  useAppSelector,
  useLinking,
  useMediaQuery,
  useTheme
});
