import {
  AuthorizationSubHeaderForProposal,
  LiveHeaderCenterChildren,
  StoreData,
  useTheme,
  ProposalsUserRequirementSection,
  ProposalRequirementSection,
  useAppSelector,
  type RootState,
  type StoreDataType,
  type ProductProposal,
  Address,
  useAppDispatch,
  showConnectModal,
} from 'ds_share'
import { abi as voteAbi } from 'ds_share/src/artifacts/contracts/src/core/StoreVote.sol/StoreVoteABI'
// import { BigNumberish } from 'ethers'
import { useEffect, useState } from 'react'
import { useLoaderData, useParams } from 'react-router-dom'
import { useAccount, useContractRead } from 'wagmi'

import { StoreProposalList } from './StoreProposalList'
import s from './index.module.css'

export const ProposalByWeek = () => {
  const proposalsData = useLoaderData() as ProductProposal[]
  const [inCorrectVotingPeriod, setInCorrectVotingPeriod] = useState(false)
  const modalCtrlDispatch = useAppDispatch()

  // const [store, setStore] = useState<StoreLiveStreamingDataProps>()
  const [userAddress, setUserAddress] = useState<string | undefined>(undefined)
  const [inCorrectPath, setInCorrectPath] = useState(false)

  const [notDeployedVoteContract, setNotDeployedVoteContract] = useState(false)
  const { isConnectPaperWallet, connectedPaperWallet } = useAppSelector((state: RootState) => state.auth)
  const isLoadingPaper = useAppSelector((state: RootState) => state.auth.isPaperAuthLoading)
  const { isConnected, address, isConnecting } = useAccount()
  const theme = useTheme()

  const { storeName } = useParams()
  const currentStore = StoreData.data.find((store: StoreDataType) => {
    return store.name === storeName
  })
  if (currentStore === undefined) {
    return <div>no exist store</div>
  }
  const contractAddress = currentStore.deployedAddresses?.StoreVote as Address
  const chainId =
    process.env.REACT_APP_VERCEL_ENV === 'production'
      ? process.env.REACT_APP_MAIN_CHAINID
      : process.env.REACT_APP_TEST_CHAINID

  if (chainId === undefined) {
    throw new Error('chainId is undefined')
  }

  const hasVotedConfig = {
    address: contractAddress,
    abi: voteAbi,
    functionName: 'hasVoted',
    chainId: Number(chainId),
  }
  // Todo: handle multiple proposals
  let votedFlag: unknown | undefined

  if (proposalsData.length > 0) {
    ;({ data: votedFlag } = useContractRead({
      ...hasVotedConfig,
      args: [Number(proposalsData[0].proposalId), userAddress],
      onError: (error) => {
        console.error('useContractRead failed at ProposalByWeek.tsx', error)
      },
      onSuccess(data) {
        console.log('useContractRead success', data, typeof data)
      },
    }))
  }

  useEffect(() => {
    if (contractAddress === undefined) {
      setNotDeployedVoteContract(true)
    }
  }, [contractAddress])

  useEffect(() => {
    if (
      !(
        location.pathname === `/${currentStore?.url}/proposals/this-week` ||
        location.pathname === `/${currentStore?.url}/proposals/last-week`
      )
    ) {
      setInCorrectPath(true)
    }

    if (location.pathname === `/${currentStore?.url}/proposals/last-week`) {
      setInCorrectVotingPeriod(true)
    }
  }, [location, isConnected, isConnectPaperWallet])

  useEffect(() => {
    if (isConnected) {
      setUserAddress(address)
    } else if (isConnectPaperWallet) {
      setUserAddress(connectedPaperWallet?.walletAddress)
    }
  }, [address, isConnected, isConnectPaperWallet])

  const renderContent = () => {
    if (notDeployedVoteContract) return <div>not deployed vote contract</div>
    if (inCorrectPath) return <div>incorrect path</div>
    if (!proposalsData) return <div>loading</div>
    if (proposalsData.length === 0) return <div>no proposals</div>

    return (
      <>
        <div className={s.sectionsContainer}>
          <div className={s.section}>
            <ProposalRequirementSection
              startTime={proposalsData[0].startTime as unknown as number}
              endTime={proposalsData[0].endTime as unknown as number}
              voteContractAddress={contractAddress}
            />
          </div>
          <div className={s.section}>
            <ProposalsUserRequirementSection
              handleShowConnectModal={() => modalCtrlDispatch(showConnectModal())}
              inCorrectVotingPeriod={inCorrectVotingPeriod}
              hasVoted={(votedFlag as boolean) ? true : false}
              isLoading={isLoadingPaper || isConnecting ? true : false}
              isLoggedIn={isConnected || isConnectPaperWallet}
            />
          </div>
        </div>
        <div className={theme?.media.isMobile ? s.mobileOwnershipContainer : s.ownershipContainer}>
          <StoreProposalList
            isLoggedIn={isConnected || isConnectPaperWallet}
            inCorrectVotingPeriod={inCorrectVotingPeriod}
            proposalVoteContractAddress={contractAddress}
            hasVoted={(votedFlag as boolean | undefined) ? true : false}
            proposals={proposalsData}
          />
        </div>
      </>
    )
  }
  return (
    <div className={s.container} style={{ background: theme?.color.secondaryLight }}>
      <div className={s.scrollContainer}>
        <div className={s.headerContainer}>
          <AuthorizationSubHeaderForProposal
            headerCenterCustomStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
            headerCenterChildren={
              <LiveHeaderCenterChildren
                logoImagePath={currentStore.logoImagePathPrimaryBlack}
                address={currentStore.place}
                addressColor={theme?.color.secondaryGray}
              />
            }
            noSideButtons
          />
        </div>
        {renderContent()}
      </div>
    </div>
  )
}
