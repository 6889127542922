import { StoreData, StoreHeaderTitle, StoreListItem, useTheme } from 'ds_share'
import { useNavigate } from 'react-router-dom'

import styles from './Stores.module.css'

export const Stores = () => {
  const theme = useTheme()

  const navigate = useNavigate()

  const handleStoreNavigate = (id: number, storeName: string) => {
    navigate(`/${storeName}`, { state: { storeId: id } })
  }

  return (
    <div className={theme?.media.isMobile ? styles.mobileContainer : styles.container}>
      <div>
        <StoreHeaderTitle title="Pick Your DeStore" handleBackNavigate={() => navigate(-1)} />
        {StoreData.data.map((store, i) => {
          return <StoreListItem key={store.id} index={i} store={store} handleStoreNavigate={handleStoreNavigate} />
        })}
      </div>
    </div>
  )
}
