import { DeStoreThemeProvider, store as redux_store } from 'ds_share'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, RouterProvider } from 'react-router-dom'
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { mainnet, polygon, polygonMumbai } from 'wagmi/chains'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { alchemyProvider } from 'wagmi/providers/alchemy'

import App, { router } from './App'
import { ApolloWrapper } from './graphql/ApolloWrapper'
import reportWebVitals from './reportWebVitals'

import './index.css'

// import { AuthProviderContainer } from './contexts/auth'
const provider_api_key = process.env.REACT_APP_ALCHEMY_PROVIDER_API_KEY
if (provider_api_key === undefined) {
  throw new Error('Alchemy provider is not defined')
}

const { publicClient, webSocketPublicClient } = configureChains(
  [mainnet, polygon, polygonMumbai],
  [alchemyProvider({ apiKey: provider_api_key })]
)

const metaMaskConnector = new MetaMaskConnector({
  chains: [polygon, polygonMumbai],
  options: {
    shimDisconnect: true,
  },
})

const walletConnectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

const walletConnectConnector = new WalletConnectConnector({
  chains: [polygon, polygonMumbai],
  options: {
    projectId: walletConnectId,
    showQrModal: true,
  },
})

const config = createConfig({
  autoConnect: true,
  connectors: [metaMaskConnector, walletConnectConnector],
  publicClient,
  webSocketPublicClient,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
// Turn off StrictMode because of AppSync subscriptions failing (https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/722)
root.render(
  <DeStoreThemeProvider>
    <WagmiConfig config={config}>
      <Provider store={redux_store}>
        <RouterProvider router={router} />
        <ApolloWrapper>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloWrapper>
      </Provider>
    </WagmiConfig>
  </DeStoreThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
