import {
  Image,
  KeyData,
  KeyDataProps,
  StoreHeaderTitle,
  StoreKeyBaseInfo,
  StoreKeyDescriptionItem,
  useTheme,
} from 'ds_share'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { A11y, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './BuyStoreNFT.module.css'

export const BuyStoreNFT = () => {
  const keys = KeyData.data

  const theme = useTheme()

  const location = useLocation()
  const navigate = useNavigate()

  const stateKey = location.state as { keyId: string } | null
  const keyId = stateKey && stateKey.keyId

  const [currentKeyIndex, setCurrentKeyIndex] = useState(
    keyId === null ? 0 : keys.findIndex((key) => key.tokenId === Number(keyId))
  )

  const handleChangeKeyId = (key: number) => {
    setCurrentKeyIndex(key)
  }

  const currentDisplayedKey: KeyDataProps = useMemo(() => {
    const {
      visual,
      name,
      price,
      address,
      standart,
      blockchain,
      isGovernanceRights,
      isVotingRight,
      isPartnerRight,
      isAccesibleBoard,
      isManager,
      paperStoreUrl,
      tokenId,
      store,
    } = keys[currentKeyIndex]

    return {
      visual,
      name,
      price,
      address,
      standart,
      blockchain,
      isGovernanceRights,
      isVotingRight,
      isPartnerRight,
      isAccesibleBoard,
      isManager,
      paperStoreUrl,
      tokenId,
      store,
    }
  }, [currentKeyIndex]) // eslint-disable-line

  const redirectPaperPage = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <div className={theme?.media.isMobile ? styles.mobileContainer : styles.container}>
      <div className={theme?.media.isMobile ? styles.mobileHeader : styles.header}>
        <StoreHeaderTitle title="NFT info" handleBackNavigate={() => navigate(-1)} />
      </div>
      <div className={theme?.media.isMobile ? styles.mobileInner : styles.inner}>
        <Swiper
          modules={[A11y, Navigation, Pagination]}
          navigation={true}
          pagination={{ clickable: true }}
          initialSlide={currentKeyIndex}
          className="key-swiper"
          onSlideChange={(s) => handleChangeKeyId(s.activeIndex)}
        >
          {keys.map(({ tokenId, visual }) => {
            return (
              <SwiperSlide key={tokenId}>
                <Image source={{ uri: visual, width: 248, height: 320 }} style={{ marginHorizontal: 'auto' }} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className={styles.keyBaseInfoContainer}>
          <StoreKeyBaseInfo currentKey={currentDisplayedKey} redirectPaperPage={redirectPaperPage} />
        </div>
        <div className={styles.keyDescriptionContainer}>
          <StoreKeyDescriptionItem currentKey={currentDisplayedKey} />
        </div>
      </div>
    </div>
  )
}
